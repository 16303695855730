import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import './AddEditRide.css';
import Button from '@material-ui/core/Button';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import RideForm from './RideForm'

class AddEditRide extends React.Component {
  constructor(){
    super();
    this.initialState = {
      currentRide: null,
      currentTrack: null,
      drivers: [],
      openRide: false,
    }
    this.state = this.initialState;

    this.handleClose = this.handleClose.bind(this);
    this.selectTrack = this.selectTrack.bind(this);
    this.removeTrack = this.removeTrack.bind(this);
    this.removeContract = this.removeContract.bind(this);
    this.openRide = this.openRide.bind(this);
    this.closeRide = this.closeRide.bind(this);

    this.id = -1;
    this.columns = [
      { dataKey: "date", title: "Date" },
      { dataKey: "ht", title: "H/T" },
      { dataKey: "driver", title: "Driver" },
      { dataKey: "pickupa", title: "Pickup Address" },
      { dataKey: "dropa", title: "Drop Address" },
      { dataKey: "price", title: "Price" },
      { dataKey: "paid", title: "Paid" }
    ];
  }

  componentDidUpdate(prevProps) {
    const { drivers, currentRide, currentTrack } = this.props;
    if(prevProps.drivers !== drivers) {
      this.setState({
        drivers
      });
    }
    if(prevProps.currentRide !== currentRide) {
      if(currentRide) {
        this.id = currentTrack ? currentTrack.id : -1
        this.setState({
          currentRide,
          currentTrack,
          openRide: (currentTrack !== null)
        });
      } else {
        this.id = -1;
        this.setState({
          ...this.initialState,
          drivers: this.state.drivers, // don't reset the driver.
        });
      }
    }
  }

  removeContract(id) {
    this.props.removeContract(id);
    this.handleClose();
  }

  handleClose() {
    this.props.onClose();
  }

  selectTrack(item) {
    this.id = item.id;
    this.setState({
      currentTrack: item,
      openRide: true
    });
  }

  removeTrack(track) {
    this.props.removeTrack(track);
  }

  openRide(type) {
    this.id = -1;

    if(type === "new") {
      this.setState({
        currentTrack: null,
        openRide: true
      });
    } else if(type === "return") {
      let currentTrack = null;
      if(this.state.currentRide && this.state.currentRide.tracks.length > 0) {
        const currentTrackDup = this.state.currentRide.tracks[0];
        currentTrack = {
          ...currentTrackDup,
          ht: "T",
          pickupd: currentTrackDup.htdate,
          htdate: currentTrackDup.pickupd,
          pickupa: currentTrackDup.dropa,
          dropa: currentTrackDup.pickupa,
        };
      }
      this.setState({
        currentTrack,
        openRide: true,
      });
    }
  }

  closeRide() {
    this.setState({
      openRide: false,
      currentTrack: null
    });
  }

  render() {
      const { role, showTable } = this.props;
      const { drivers, currentRide, currentTrack } = this.state;

      return <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          disableEscapeKeyDown={true}
          fullWidth={true}
          maxWidth="md">
          <DialogTitle id="form-dialog-title">Ride overview</DialogTitle>
          <DialogContent>
            { showTable && <div className={clsx(this.state.openRide && "decreaseOpacity")}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                {(!currentRide || !currentRide.tracks) && !this.state.openRide && <div className="first-ride">
                    Hey, add your first ride!
                  </div>
                }
                {currentRide && currentRide.tracks &&
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        {this.columns.map(item => (
                          <TableCell key={item.dataKey}>
                              {item.title}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentRide && currentRide.tracks.sort((a, b) => moment(a.pickupd) - moment(b.pickupd)).map(item => {
                        const driver = drivers.find(d => d.id === item.driver)

                        const address = item.pickupa;
                        let street = address;
                        let city = "";
                        const addressSplitted = address.split(",");
                        if(addressSplitted.length > 1) {
                          street = addressSplitted[0];
                          city = addressSplitted[1];
                        }

                        const addressd = item.dropa;
                        let streetd = addressd;
                        let cityd = "";
                        const addressdSplitted = addressd.split(",");
                        if(addressdSplitted.length > 1) {
                          streetd = addressdSplitted[0];
                          cityd = addressdSplitted[1];
                        }

                        return <TableRow key={item.id}>
                          <TableCell style={{minWidth: "100px", padding: "3px"}}>
                            <Tooltip title="Edit track" placement="top">
                              <EditIcon className="icon" onClick={() => this.selectTrack(item)}/>
                            </Tooltip>
                            { role === "admin" && <Tooltip title="Delete track" placement="top">
                              <DeleteIcon className="icon" onClick={() => this.removeTrack(item)} />
                            </Tooltip> }
                          </TableCell>
                          <TableCell> { item.pickupd.format("DD/MM") } </TableCell>
                          <TableCell> { item.ht } </TableCell>
                          <TableCell> { driver ? driver.username : "-" } </TableCell>
                          <TableCell>
                            {street}
                            {city.length > 0 && <span>
                              <br/>
                              {city}
                            </span>}
                          </TableCell>
                          <TableCell>
                            {streetd}
                            {cityd.length > 0 && <span>
                              <br/>
                              {cityd}
                            </span>}
                          </TableCell>
                          { (role === "admin" || role == "driver") && <TableCell> €{ item.price } </TableCell> }
                          { (role === "admin" || role == "driver") && <TableCell> { item.paid  && <DoneIcon/> } </TableCell> }
                        </TableRow>
                      })}
                    </TableBody>
                  </Table> }
                </Grid>
              </Grid>
            </div> }

            { this.state.openRide && <RideForm
              id={this.id}
              role={role}
              drivers={drivers}
              saveRide={this.props.saveRide}
              removeContract={this.removeContract}
              closeRide={this.closeRide}
              handleClose={this.handleClose}
              showTable={this.props.showTable}
              currentTrack={currentTrack}
              currentRide={currentRide} /> }

          </DialogContent>

          { showTable &&

            <DialogActions>

            { role === "admin" &&

              <Button disabled={true}>
                Show invoice
              </Button>
            }

            { (role === "admin" || role === "planner") && <Button disabled={this.state.openRide } onClick={() => this.openRide("new")} color="primary">
                New ride
              </Button>

            }

            { (role === "admin" || role === "planner") && currentRide && <Button disabled={ this.state.openRide } onClick={() => this.openRide("return")} color="primary">
                Return ride
              </Button>
            }

              <Button disabled={ this.state.openRide } onClick={() => this.handleClose()} color="primary">
                Cancel
              </Button>
            </DialogActions>
          }
        </Dialog>
      </div>;
  }
}

export default AddEditRide;
