import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

class DigitalBoard extends React.Component {

  constructor() {
    super();
    this.state = {}
  }

  componentDidUpdate(prevProps){
    const { currentTrack } = this.props;
    if(currentTrack && prevProps.currentTrack !== currentTrack) {
      this.setState({
        ...currentTrack
      })
    }
  }

  render() {
    return (
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullScreen={true}>
          <DialogContent>
            <Grid container spacing={2}>
              <div style={{width: "100%", textAlign: "center"}}>
                <img src="/logo.png" alt="pdr-logo" style={{width: "40%", marginTop: "10vh"}}/>
              </div>
              <div style={{margin: "0 auto", fontSize: "90px", textAlign: "center", width: "100%", marginTop: "10vh"}}>
                {this.props.currentTrack && this.props.currentTrack.name}
              </div>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
    );
  }
}

export default DigitalBoard;
