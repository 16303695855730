import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import './MarkAsPaid.css';
import DoneIcon from '@material-ui/icons/Done';
import moment from 'moment';

class MarkAsPaid extends React.Component {

  constructor() {
    super();
    this.onSubmit = this.onSubmit.bind(this);

    this.changePaid = this.changePaid.bind(this);
    this.changePaidAll = this.changePaidAll.bind(this);
    this.changePaymentMethod = this.changePaymentMethod.bind(this);
    this.changeDetails = this.changeDetails.bind(this);
    this.changeAmountReceived = this.changeAmountReceived.bind(this);
    this.changeBookNumber = this.changeBookNumber.bind(this);

    this.state = {
      amountReceived: "",
      bookNumber: "",
      paidAll: false,
    };
  }

  componentDidUpdate(prevProps){
    const { currentTrack, currentRide } = this.props;
    if(currentTrack && prevProps.currentTrack !== currentTrack) {
      this.setState({
        ...currentTrack
      })
    }
    if(currentRide && prevProps.currentRide !== currentRide) {
      this.setState({
        paidAll: currentRide.paidAll
      })
    }
  }

  onSubmit() {
    const { currentTrack, currentRide } = this.props;
    this.props.onSubmit(currentTrack.id, currentRide, {
      ...this.state,
    });
    this.props.handleClose();
  }

  changePaid (e){
    this.setState({ paid: e.target.checked });
  }

  changePaidAll (e){
    this.setState({ paidAll: e.target.checked });
    if(e.target.checked){
      this.setState({ paid : true });
    }
  }

  changeDetails (e){
    this.setState({ details: e.target.value });
  }

  changeAmountReceived (e){
    this.setState({ amountReceived: e.target.value });
  }

  changePaymentMethod (e){
    this.setState({ paymentmethod: e.target.value });
  }

  changeBookNumber (e){
    this.setState({ bookNumber: e.target.value });
  }

  render() {

    // Calcalate how many rides are paid.
    let paidRides = 0;
    this.props.currentRide && this.props.currentRide.tracks.forEach(t => {
      if(t.paid){
        paidRides++
      }
    })

    // Check if hee paid all the rides.
    let paidAllRides = false;
    if (this.props.currentRide && paidRides === this.props.currentRide.tracks.length) {
      paidAllRides = true;
    }

    // Write a descriptive text.
    let descriptionPaidRides = "";
    if(this.props.currentRide && this.props.currentTrack) {
      if (paidAllRides) {
        descriptionPaidRides = `
        The customer paid all (${this.props.currentRide.tracks.length}) rides, no action needed.`;
        if(this.props.currentRide.paymentReceivedBy) {
          descriptionPaidRides += ` Payments were received by ${this.props.currentRide.paymentReceivedBy}.`;
        }
      } else {
        descriptionPaidRides = `The customer has ${this.props.currentRide.tracks.length} linked rides of which ${paidRides} ${(paidRides === 1) ? "ride is" : "rides are"} paid.`;
      }
    }

    return (
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          className="MarkAsPaid"
          maxWidth="md">
          <DialogTitle id="alert-dialog-title">{ this.props.title }</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              { this.props.currentRide && <Grid item xs={12} md={12}>
                  { descriptionPaidRides }
                  {
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Driver</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Pickup</TableCell>
                          <TableCell>Drop</TableCell>
                          <TableCell>Paid</TableCell>
                          <TableCell>B/F</TableCell>
                          <TableCell>Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          this.props.currentTrack && this.props.currentRide.tracks && this.props.currentRide.tracks.sort((a, b) => moment(a.pickupd) - moment(b.pickupd)).map(t => {

                            let driver = "";
                            if(this.props.drivers) {
                              const d = this.props.drivers.find(d => d.id === t.driver);
                              if(d) {
                                driver = d.username;
                              }
                            }

                            return <TableRow className={clsx({"currentTrack": t.id === this.props.currentTrack.id})} key={t.id}>
                              <TableCell>{ driver }</TableCell>
                              <TableCell>{ moment(t.pickupd).format("DD/MM") }</TableCell>
                              <TableCell>{t.pickupa}</TableCell>
                              <TableCell>{t.dropa}</TableCell>
                              <TableCell>{t.paid && <DoneIcon/> }</TableCell>
                              <TableCell align="center">{t.bf.toUpperCase()}</TableCell>
                              <TableCell>{t.price}EUR</TableCell>
                            </TableRow>
                          })
                        }
                      </TableBody>
                    </Table>
                  }
                  </Grid>
              }
              <Grid item xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={this.state.paidAll}
                        checked={this.state.paid || this.state.paidAll}
                        onChange={this.changePaid}
                        value="paid"
                        color="primary"
                      />
                    }
                    label="Pay this ride"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.paidAll}
                        onChange={this.changePaidAll}
                        value="paidall"
                        color="primary"
                      />
                    }
                    label="Paid all rides"
                  />

                  <FormControl fullWidth variant="filled">
                    <InputLabel>Payment method</InputLabel>
                    <Select
                      value={this.state.paymentmethod}
                      onChange={this.changePaymentMethod}
                      margin="dense"
                      inputProps={{
                        name: 'paymentmethod',
                        id: 'paymentmethod-simple',
                      }}
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value="payconic">Payconic</MenuItem>
                      <MenuItem value="mastercard">Mastercard/Visa</MenuItem>
                      <MenuItem value="cash">Cash</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    id="amount"
                    label="Euros Received"
                    margin="dense"
                    type="text"
                    fullWidth
                    value={ this.state.amountReceived }
                    onChange={ this.changeAmountReceived }/>

                  <TextField
                    id="amount"
                    label="Book number"
                    margin="dense"
                    type="text"
                    fullWidth
                    value={ this.state.bookNumber }
                    onChange={ this.changeBookNumber }/>

                  <TextField
                    id="details"
                    label="Details"
                    margin="dense"
                    type="text"
                    fullWidth
                    value={ this.state.details }
                    onChange={ this.changeDetails }/>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.onSubmit} color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
    );
  }
}

export default MarkAsPaid;
