import React from 'react';
import InputIcon from '@material-ui/icons/Input';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import { connect } from 'react-redux';
import { logout } from '../../actions'

class Logout extends React.Component {

  constructor() {
    super();
    this.signout = this.signout.bind(this);
  }

  signout() {
    this.props.dispatchLogout();
  }

  render(){
    return <IconButton onClick={this.signout} color="inherit">
      <Badge color="secondary">
        <InputIcon></InputIcon>
      </Badge>
    </IconButton>
  }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchLogout: () => dispatch(logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
