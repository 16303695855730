import React from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import AddEditUser from '../components/AddEditUser/AddEditUser';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit'
import DomainDisabled from '@material-ui/icons/DomainDisabled'
import ConfirmDialog from '../components/ConfirmDialog/ConfirmDialog';
import Tooltip from '@material-ui/core/Tooltip';
import "./Users.css";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { deleteUser, addEditUser, getUsers } from '../actions'

class Users extends React.Component {

  constructor() {
    super();
    this.state = {
      open: false,
      openDelete: false,
      currentUser: null
    };
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.handleClickAddOpen = this.handleClickAddOpen.bind(this);
    this.handleClickEditOpen = this.handleClickEditOpen.bind(this);
    this.handleDeleteOpen = this.handleDeleteOpen.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    this.props.dispatchGetUsers();
  }

  save(id, data) {
    this.props.dispatchAddEditUser(id, data);
  }

  delete() {
    const { id } = this.state.currentUser;
    this.props.dispatchDeleteUser(id);
  }

  handleClickAddOpen() {
    this.setState({
      open: true,
      currentUser: null
    });
  }

  handleClickEditOpen(user) {
    this.setState({
      open: true,
      currentUser: user
    });
  }

  handleClose() {
    this.setState({
      open: false
    });
  }

  handleDeleteOpen(user) {
    this.setState({
      openDelete: true,
      currentUser: user
    });
  }

  handleDeleteClose() {
    this.setState({
      openDelete: false,
    });
  }

  render(){
    const { listOfUsers } = this.props;

    return <div>
      <Grid container spacing={0}>
        <Paper className="fullWidth">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Actions</TableCell>
                <TableCell>Id</TableCell>
                <TableCell align="right">Username</TableCell>
                <TableCell align="right">E-mail</TableCell>
                <TableCell align="right">Phone</TableCell>
                <TableCell align="right">Role</TableCell>
                <TableCell align="right">Active</TableCell>
                <TableCell align="right">Color</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listOfUsers && listOfUsers.map(row => (
                <TableRow key={row.id}>
                  <TableCell padding="checkbox" style={{ width: "150px" }}>
                    { row.username !== 'admin' && (
                      <div>
                        <Tooltip title="Edit user" placement="top">
                          <EditIcon onClick={() => this.handleClickEditOpen(row)} className="icon"/>
                        </Tooltip>
                        <Tooltip title="Delete user" placement="top">
                          <DeleteIcon onClick={() => this.handleDeleteOpen(row)} className="icon"/>
                        </Tooltip>
                        <Tooltip title="Disable user" placement="top">
                          <DomainDisabled className="inactive"/>
                        </Tooltip>
                      </div>
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell align="right">{row.username}</TableCell>
                  <TableCell align="right">{row.email}</TableCell>
                  <TableCell align="right">{row.phone}</TableCell>
                  <TableCell align="right">{row.role}</TableCell>
                  <TableCell align="right">{row.active ? "Yes" : "No"}</TableCell>
                  <TableCell align="right" style={{backgroundColor: row.color}}>{row.color}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Tooltip title="Add a new user" placement="top">
        <Fab color="primary" aria-label="Add" className="add" onClick={this.handleClickAddOpen}>
          <AddIcon/>
        </Fab>
      </Tooltip>
      <AddEditUser
        saveUser={this.save}
        currentUser={this.state.currentUser}
        open={this.state.open}
        onClose={this.handleClose} />

      { this.state.currentUser &&
        <ConfirmDialog
          onSubmit={this.delete}
          title="Delete user"
          message={ "You're about to delete '" + this.state.currentUser.username + "'. Are you sure?" }
          open={this.state.openDelete}
          handleClose={this.handleDeleteClose} /> }
    </div>
  }
}

const mapStateToProps = (state, ownProps) => ({
  listOfUsers: state.users.list
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchAddEditUser: (id, data) => dispatch(addEditUser(id, data)),
  dispatchDeleteUser: (id) => dispatch(deleteUser(id)),
  dispatchGetUsers: () => dispatch(getUsers())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));
