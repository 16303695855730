import API from './api';

export function doAddCar(data, onSuccess, onError) {
  API.post(`cars`, {
    ...data
  })
  .then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}

export function doEditCar(id, data, onSuccess, onError) {
  API.put(`cars`, {
    id,
    ...data
  })
  .then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}

export function doDeleteCar(id, onSuccess, onError) {
  API.delete(`cars/${id}`)
  .then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}

export function doGetCars(onSuccess, onError) {
  API.get(`cars`)
  .then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}
