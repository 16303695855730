import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import users from './users';
import auth from './auth';
import rides from './rides';
import reports from './reports';
import cars from './cars';

export default (history) => combineReducers({
  auth,
  users,
  rides,
  reports,
  cars,
  router: connectRouter(history),
});
