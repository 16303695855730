import { doAddCar, doEditCar, doDeleteCar, doGetCars } from '../api/car';

export const getCars = () => {
  return function (dispatch) {
    doGetCars((cars) => {
      // get users
      dispatch({
        type: 'GET_CARS',
        cars: cars.data
      });
    }, (error) => {
      //console.log("something went wrong :(")
    });
  }
}

export const addEditCar = (id, data) => {
  return function (dispatch) {
    // We need to add a new user.
    if(id === -1) {
      doAddCar(data, (d) => {
        // add user to the existing list.
        dispatch({
          type: 'ADD_CAR',
          car: d
        });
      }, (error) => {
        //console.log("something went wrong :(")
      });
    } else {
      doEditCar(id, data, (d) => {
        // edit user in the list.
        dispatch({
          type: 'EDIT_CAR',
          id,
          car: d
        });
      }, (error) => {
        //console.log("something went wrong :(")
      });
    }
  }
}

export const deleteCar = (id) => {
  return function (dispatch) {
    doDeleteCar(id, (d) => {
      // add user to the existing list.
      dispatch({
        type: 'DELETE_CAR',
        id
      });
    }, (error) => {
      //console.log("something went wrong :(")
    });
  }
}
