import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import './ConfirmDialog.css';

class ConfirmDialog extends React.Component {

  constructor() {
    super();
    this.onSubmit = this.onSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAlignment = this.handleAlignment.bind(this);
    this.state = {
      msg: "",
      message: "",
      messageEN: "",
      selectedLanguage: "nl",
    }
  }

  componentDidMount() {
    this.setState({
      message: this.props.message
    });
  }

  componentDidUpdate(prevProps) {
    if(prevProps.message !== this.props.message) {
      this.setState({
        msg: this.props.message,
        message: this.props.message,
        messageEN: this.props.messageEN,
        translation: this.props.translation,
      });
    }
  }

  onSubmit() {
    this.props.onSubmit(this.state.msg);
    this.props.handleClose();
  }

  handleChange(event) {
    const { selectedLanguage } = this.state
    const { value } = event.target

    if(selectedLanguage === "nl") {
      this.setState({
        message: value,
        msg: value
      });
    } else if(selectedLanguage === "en") {
      this.setState({
        messageEN: value,
        msg: value
      });
    }
  }

  handleAlignment(event, value) {
    this.setState({
      selectedLanguage: value
    });

    if(value === "nl") {
      this.setState({
        msg: this.state.message
      });
    } else if(value === "en") {
      this.setState({
        msg: this.state.messageEN
      });
    }
  }

  render() {

    const { message, messageEN, translation, selectedLanguage } = this.state;
    let msg = message;
    if(translation){
      if(selectedLanguage === "nl") {
        msg = message;
      } else if(selectedLanguage === "en") {
        msg = messageEN;
      }
    }

    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"lg"}
          fullWidth={true}
          className="confirmdialog"
        >
            <DialogTitle id="alert-dialog-title">{ this.props.title }</DialogTitle>
            <DialogContent>
            { this.props.translation && <ToggleButtonGroup value={selectedLanguage} exclusive onChange={(event, value) => this.handleAlignment(event, value)}>
              <ToggleButton value="nl">
                NL
              </ToggleButton>
              <ToggleButton value="en">
                EN
              </ToggleButton>
            </ToggleButtonGroup> }
            { !this.props.editable && <DialogContentText id="alert-dialog-description" dangerouslySetInnerHTML={{__html: msg}}></DialogContentText> }
            { this.props.editable && <TextField
                id="standard-multiline-flexible"
                label="Message"
                multiline
                rowsMax="100"
                fullWidth={true}
                value={msg || ""}
                onChange={(e) => this.handleChange(e)}
                margin="normal"/> }
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleClose} color="primary">
              Cancel
            </Button>
            <Button disabled={this.props.canNotSubmit} onClick={this.onSubmit} color="primary" autoFocus>
              { this.props.button ? this.props.button : "Delete" }
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ConfirmDialog;
