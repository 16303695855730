import React from 'react';
import Grid from '@material-ui/core/Grid';
import './AddEditRide.css';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import clsx from 'clsx';
import './RideForm.css';

class RideForm extends React.Component {

  constructor(){
    super();
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeDriver = this.changeDriver.bind(this);
    this.changeHT = this.changeHT.bind(this);
    this.changePickUpDate = this.changePickUpDate.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.changeName = this.changeName.bind(this);
    this.changeTel = this.changeTel.bind(this);
    this.changePickupAddress = this.changePickupAddress.bind(this);
    this.changeDropAddress = this.changeDropAddress.bind(this);
    this.changeNumberOfPersons = this.changeNumberOfPersons.bind(this);
    this.changePrice = this.changePrice.bind(this);
    this.changeAmountReceived = this.changeAmountReceived.bind(this);
    this.changeBookNumber = this.changeBookNumber.bind(this);
    this.changeBF = this.changeBF.bind(this);
    this.changeIsHT = this.changeIsHT.bind(this);
    this.changeDetails = this.changeDetails.bind(this);
    this.changeHTDate = this.changeHTDate.bind(this);
    this.changeTravelTime = this.changeTravelTime.bind(this);
    this.changeWorkingtime = this.changeWorkingtime.bind(this);
    this.changeValidated = this.changeValidated.bind(this);
    this.changeInvoiceCreated = this.changeInvoiceCreated.bind(this);
    this.changePaid = this.changePaid.bind(this);
    this.changePaymentMethod = this.changePaymentMethod.bind(this);

    this.initialState = {
      driver: "",
      ht: "",
      pickupd: moment(new Date()),
      email: "",
      name: "",
      tel: "",
      pickupa: "",
      dropa: "",
      numberp: "",
      price: "",
      amountReceived: "",
      bookNumber: "",
      bf: "",
      details: "",
      contract: "",
      htdate: moment(new Date()).startOf('day'),
      traveltime: "",
      workingtime: "",
      validated: false,
      invoiceCreated: false,
      paid: false,
      paymentmethod: "",
      isHT: false,
      error: false
    };
    this.state = this.initialState;
  }

  componentDidMount(){
    // if edit, we can initialise the state
    // and assign the id.
    const { id, currentTrack } = this.props;
    if(id && currentTrack) {
      this.setState({
        ...currentTrack,
        error: false
      })
    }
  }

  validateForm() {
    const { driver, ht, /* email, tel,*/ name,  pickupd, pickupa, dropa, numberp, price, bf, /*details,*/ htdate, traveltime } = this.state;
    //if(!this.checkIfValid(driver)) {
    //  return false;
    //}
    if(!this.checkIfValid(ht)) {
      return false;
    }
    if(!pickupd.isValid()) {
      return false;
    }
    //if(!this.checkIfValid(email)) {
    //  return false;
    //}
    if(!this.checkIfValid(name)) {
      return false;
    }
    //if(!this.checkIfValid(tel)) {
    //  return false;
    //}
    if(!this.checkIfValid(pickupa)) {
      return false;
    }
    if(!this.checkIfValid(dropa)) {
      return false;
    }
    if(!this.checkIfValid(numberp)) {
      return false;
    }
    //if(!this.checkIfValid(price)) {
    //  return false;
    //}
    //if(!this.checkIfValid(bf)) {
    //  return false;
    //}
    //if(!this.checkIfValid(details)) {
    //  return false;
    //}
    if(!htdate.isValid()) {
      return false;
    }
    //if(!this.checkIfValid(traveltime)) {
    //  return false;
    //}
    return true;
  }

  handleClose(event) {
    if(!this.props.showTable) {
      this.props.handleClose();
    } else {
      this.props.closeRide();
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    if(this.validateForm()){
      // Send data..
      this.props.saveRide(this.props.id, this.props.currentRide, { ...this.state })
      this.setState({error: false});

      if(!this.props.showTable) {
        this.props.handleClose();
      } else {
        this.props.closeRide();
      }
    }
    else {
      this.setState({error: true});
    }
  }

  checkIfValid(value) {
    return value && value.length > 0;
  }

  changeDriver(e) {
    this.setState({ driver: e.target.value });
  }

  changeHT (e){
    this.setState({ ht: e.target.value });
  }

  changePickUpDate (date) {
    this.setState({ pickupd: moment(new Date(date)) });
  }

  changeEmail (e){
    this.setState({ email: e.target.value });
  }

  changeName (e){
    this.setState({ name: e.target.value });
  }

  changeTel (e){
    this.setState({ tel: e.target.value });
  }

  changePickupAddress (e){
    this.setState({ pickupa: e.target.value });
  }

  changeDropAddress (e){
    this.setState({ dropa: e.target.value });
  }

  changeNumberOfPersons (e){
    this.setState({ numberp: e.target.value });
  }

  changePrice (e){
    this.setState({ price: e.target.value });
  }

  changeAmountReceived (e){
    this.setState({ amountReceived: e.target.value });
  }

  changeBookNumber (e){
    this.setState({ bookNumber: e.target.value });
  }

  changeBF (e){
    this.setState({ bf: e.target.value });
  }

  changePaymentMethod (e){
    this.setState({ paymentmethod: e.target.value });
  }

  changeIsHT (e){
    this.setState({ isHT: e.target.checked });
  }

  changeDetails (e){
    this.setState({ details: e.target.value });
  }

  changeHTDate (date) {
    this.setState({ htdate: moment(new Date(date)).startOf('day') });
  }

  changeTravelTime (e){
    this.setState({ traveltime: e.target.value });
  }

  changeWorkingtime (e){
    this.setState({ workingtime: e.target.value });
  }

  changePaid (e){
    this.setState({ paid: e.target.checked });
  }

  changeInvoiceCreated (e){
    this.setState({ invoiceCreated: e.target.checked });
  }

  changeValidated (e){
    this.setState({ validated: e.target.checked });
  }

  render() {
      const { error } = this.state;
      const { role, showTable } = this.props;

      return <div className={clsx(showTable && "seperator")}>
          <form onSubmit={this.handleSubmit}>
                { error && <span className="error">Please fill-in all fields correctly.</span> }
                <Grid container spacing={2}>
                  { (role === 'admin' || role === 'planner') && <Grid item xs={12} md={4} >
                    <FormControl fullWidth variant="filled">
                      <InputLabel>Heen/Terug</InputLabel>
                      <Select onChange={this.changeHT}
                        required
                        value={this.state.ht}
                        margin="dense"
                        label="Heen/Terug"
                        inputProps={{
                          name: 'ht',
                          id: 'ht-simple',
                        }}
                      >
                        <MenuItem value="H">Heen</MenuItem>
                        <MenuItem value="T">Terug</MenuItem>
                      </Select>
                    </FormControl>

                    { role === 'admin' && <FormControl fullWidth variant="filled">
                      <InputLabel>Driver</InputLabel>
                      <Select onChange={this.changeDriver}
                        value={this.state.driver}
                        margin="dense"
                        inputProps={{
                          name: 'driver',
                          id: 'driver-simple',
                        }}
                      >
                        <MenuItem
                          key="-"
                          value="-"
                          style={{backgroundColor: "white"}}
                        >
                          -
                        </MenuItem> 
                        { this.props.drivers.map(driver => (
                          <MenuItem
                            key={driver.id}
                            value={driver.id}
                            style={{backgroundColor: driver.color}}
                          >
                            {driver.username}
                          </MenuItem> ))}
                      </Select>
                    </FormControl> }

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDateTimePicker
                        fullWidth
                        ampm={false}
                        label="Pickup date"
                        format="dd/MM/yyyy HH:mm"
                        value={this.state.pickupd}
                        onChange={this.changePickUpDate}
                      />
                    </MuiPickersUtilsProvider>

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.isHT}
                          onChange={this.changeIsHT}
                          value="paid"
                          color="primary"
                        />
                      }
                      label="H/T date"
                    />

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        disabled={!this.state.isHT}
                        ampm={false}
                        label="H/T Date"
                        format="dd/MM/yyyy"
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        value={this.state.htdate}
                        onChange={date => this.changeHTDate(date)}
                      />
                    </MuiPickersUtilsProvider>

                  </Grid> }

                  <Grid item xs={12} md={4}>

                    <TextField
                      margin="dense"
                      id="pickupa"
                      label="Pickup address"
                      type="text"
                      fullWidth
                      value={ this.state.pickupa }
                      onChange={ this.changePickupAddress }/>

                    <TextField
                      margin="dense"
                      id="dropa"
                      label="Drop address"
                      type="text"
                      fullWidth
                      value={ this.state.dropa }
                      onChange={ this.changeDropAddress }/>

                    { (role === 'admin' || role === 'driver')  && <TextField
                      margin="dense"
                      id="traveltime"
                      label="Travel time (minutes)"
                      type="text"
                      fullWidth
                      value={this.state.traveltime}
                      onChange={ this.changeTravelTime }/> }

                    { (role === 'admin' || role === 'driver')  && <TextField
                      margin="dense"
                      id="workingtime"
                      label="Actual working time (minutes)"
                      disabled={this.props.role !== 'admin'}
                      type="text"
                      fullWidth
                      value={this.state.workingtime}
                      onChange={ this.changeWorkingtime }/> }

                    <TextField
                      margin="dense"
                      id="numberp"
                      label="Number of persons"
                      type="text"
                      fullWidth
                      value={ this.state.numberp }
                      onChange={ this.changeNumberOfPersons }/>

                    { (role === 'admin' || role === 'driver')  && <TextField
                      margin="dense"
                      id="price"
                      label="Price"
                      type="text"
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                      }}
                      value={ this.state.price }
                      onChange={ this.changePrice }/> }

                    { (role === 'admin' || role === 'driver')  && <TextField
                      margin="dense"
                      id="amount"
                      label="Amount Received"
                      type="text"
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                      }}
                      value={ this.state.amountReceived }
                      onChange={ this.changeAmountReceived }/> }

                    <TextField
                      margin="dense"
                      id="amount"
                      label="Reference number"
                      type="text"
                      fullWidth
                      value={ this.state.bookNumber }
                      onChange={ this.changeBookNumber }/>

                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <FormControlLabel
                          disabled={this.props.role !== 'admin'}
                          control={
                            <Checkbox
                              checked={this.state.validated}
                              onChange={this.changeValidated}
                              value="validated"
                              color="primary"
                            />
                          }
                          label="Validated"
                        />
                      </Grid>
                      { (role === 'admin' || role === 'driver')  && <Grid item xs={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.paid}
                              onChange={this.changePaid}
                              value="paid"
                              color="primary"
                            />
                          }
                          label="Paid"
                        />
                      </Grid> }

                      { (role === 'admin' || role === 'driver')  && <Grid item xs={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.invoiceCreated}
                              onChange={this.changeInvoiceCreated}
                              value="invoice created"
                              color="primary"
                            />
                          }
                          label="Invoice created"
                        />
                      </Grid> }
                    </Grid>

                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      disabled={this.props.role !== 'admin' && this.props.role !== 'planner'}
                      margin="dense"
                      id="name"
                      label="Name"
                      type="text"
                      fullWidth
                      value={this.state.name}
                      onChange={ this.changeName }/>

                    <TextField
                      disabled={this.props.role !== 'admin' && this.props.role !== 'planner'}
                      margin="dense"
                      id="email"
                      label="E-mail"
                      type="text"
                      fullWidth
                      value={this.state.email}
                      onChange={ this.changeEmail }/>

                    <TextField
                      disabled={this.props.role !== 'admin' && this.props.role !== 'planner'}
                      margin="dense"
                      id="tel"
                      label="Telephone number"
                      type="text"
                      fullWidth
                      value={ this.state.tel }
                      onChange={ this.changeTel }/>

                    <TextField
                      id="details"
                      label="Details"
                      margin="dense"
                      type="text"
                      fullWidth
                      value={ this.state.details }
                      onChange={ this.changeDetails }/>

                    <FormControl fullWidth variant="filled">
                      <InputLabel>Bon/Factuur</InputLabel>
                      <Select
                        value={this.state.bf}
                        onChange={this.changeBF}
                        margin="dense"
                        inputProps={{
                          name: 'bf',
                          id: 'bf-simple',
                        }}
                      >
                        <MenuItem value="b">Bon</MenuItem>
                        <MenuItem value="f">Factuur</MenuItem>
                        <MenuItem value="n">-</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth variant="filled">
                      <InputLabel>Payment method</InputLabel>
                      <Select
                        value={this.state.paymentmethod}
                        onChange={this.changePaymentMethod}
                        margin="dense"
                        inputProps={{
                          name: 'paymentmethod',
                          id: 'paymentmethod-simple',
                        }}
                      >
                        <MenuItem value="-">-</MenuItem>
                        <MenuItem value="payconic">Payconic</MenuItem>
                        <MenuItem value="mastercard">Mastercard/Visa</MenuItem>
                        <MenuItem value="cash">Cash</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
            <DialogActions>
  
              <Button color="primary" type="submit">
                { this.props.id !== -1 ? "Edit" : "Add" } Ride
              </Button>
              <Button onClick={() => this.handleClose()} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
      </div>;
  }
}

export default RideForm;
