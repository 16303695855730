const rides = (state = {
  list: [],
  listByDay: {},
  filteredList: [],
}, action) => {
  switch (action.type) {
    case 'GET_RIDES':
      return {
        ...state,
        list: action.rides
      };
    case 'GET_FILTERED_RIDES':
      return {
        ...state,
        filteredList: action.filtered_rides
      };
    case 'GET_RIDES_BY_DAY':
      return {
        ...state,
        listByDay: {
          ...state.listByDay,
          [action.day]: action.rides,
        }
      }
    case 'ADD_RIDE':
      return {
        ...state,
        list: [
          ...state.list,
          action.ride
        ]
      };
    case 'ADD_TRACK':
      return {
        ...state,
        list: state.list.map((ride, i) => {
          if(ride.id === action.ride.id) {
            return {
              ...ride,
              tracks: [
                ...ride.tracks,
                action.track,
              ]
            }
          }
          return ride;
        })
      };
    case 'EDIT_TRACK':
      return {
        ...state,
        list: state.list.map((ride, i) => {
          if(ride.id === action.track.ride) {
            const index = ride.tracks.findIndex(t => t.id === action.track.id);
            return {
              ...ride,
              tracks: [
                ...ride.tracks.slice(0, index),
                action.track,
                ...ride.tracks.slice(index + 1)
              ]
            }
          }
          return ride;
        }),
        listByDay: Object.keys(state.listByDay).map((day, i) => {
          const ridesOnDay = state.listByDay[day];
          return [
            day,
            ridesOnDay
          ];
        }).map(val => {
          const day = val[0];
          let rides = val[1];
          if(rides) {
            rides = rides.map(r => {
              if (r.id === action.track.ride) {
                const index = r.tracks.findIndex(t => t.id === action.track.id);
                return {
                  ...r,
                  tracks: [
                    ...r.tracks.slice(0, index),
                    action.track,
                    ...r.tracks.slice(index + 1)
                  ]
                };
              }
              return r;
            });
          }
          return [day, rides];
        }).reduce((obj, val) => {
          obj[val[0]] = val[1];
          return obj;
        }, {}),
      };
    case 'SAVE_CONTRACT':
      return {
        ...state,
        list: state.list.map((ride, i) => ride.id === action.id ? {
          ...ride,
          contract: action.contract,
        } : ride)
      };
    case 'SEND_CHIRON':
      const payload = action.data;
      const track = action.track;

      let chironDirection = "chironDeparture"
      if(payload.chironDirection === "arrival") {
        chironDirection = "chironArrival"
      }

      return {
        ...state,
        list: state.list.map((ride, i) => {
          if(ride.id === action.track.ride) {
            const index = ride.tracks.findIndex(t => t.id === action.track.id);
            return {
              ...ride,
              tracks: [
                ...ride.tracks.slice(0, index),
                {
                  ...ride.tracks[index],
                  [chironDirection]: true
                },
                ...ride.tracks.slice(index + 1)
              ]
            }
          }
          return ride;
        }),
        listByDay: Object.keys(state.listByDay).map((day, i) => {
          const ridesOnDay = state.listByDay[day];
          return [
            day,
            ridesOnDay
          ];
        }).map(val => {
          const day = val[0];
          let rides = val[1];
          if(rides) {
            rides = rides.map(r => {
              if (r.id === action.track.ride) {
                const index = r.tracks.findIndex(t => t.id === action.track.id);
                return {
                  ...r,
                  tracks: [
                    ...r.tracks.slice(0, index),
                    {
                      ...r.tracks[index],
                      [chironDirection]: true
                    },
                    ...r.tracks.slice(index + 1)
                  ]
                };
              }
              return r;
            });
          }
          return [day, rides];
        }).reduce((obj, val) => {
          obj[val[0]] = val[1];
          return obj;
        }, {}),

      };
    case 'SEND_SMS':
      return {
        ...state,
        list: state.list.map((ride, i) => {
          if(ride.id === action.track.ride) {
            const index = ride.tracks.findIndex(t => t.id === action.track.id);
            return {
              ...ride,
              tracks: [
                ...ride.tracks.slice(0, index),
                {
                  ...ride.tracks[index],
                  smsSend: true
                },
                ...ride.tracks.slice(index + 1)
              ]
            }
          }
          return ride;
        })
      };
    case 'SEND_EMAIL':
      return {
        ...state,
        list: state.list.map((ride, i) => {
          if(ride.id === action.track.ride) {
            return {
              ...ride,
              emailSend: true
            }
          }
          return ride;
        })
      };
    case 'UPDATE_DRIVER':
      return {
        ...state,
        list: state.list.map((ride, i) => {
          if(ride.id === action.track.ride) {
            const index = ride.tracks.findIndex(t => t.id === action.track.id);
            return {
              ...ride,
              tracks: [
                ...ride.tracks.slice(0, index),
                {
                  ...action.track,
                  driver: action.driver
                },
                ...ride.tracks.slice(index + 1)
              ]
            }
          }
          return ride;
        })
      };
    case 'DELETE_RIDE':
      return {
        ...state,
        list: state.list.map((ride, i) => {
          if(ride.id === action.track.ride) {
            return {
              ...ride,
              tracks: ride.tracks.filter(t => t.id !== action.track.id)
            }
          }
          return ride;
        })
      };
    default:
      return state
  }
}

export default rides;
