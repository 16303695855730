const users = (state = {
  list: []
}, action) => {
  switch (action.type) {
    case 'GET_USERS':
      return {
        ...state,
        list: action.users
      };
    case 'ADD_USER':
      return {
        ...state,
        list: [
          ...state.list,
          action.user
        ]
      };
    case 'EDIT_USER':
      return {
        ...state,
        list: state.list.map((user, i) => user.id === action.id ? action.user : user)
      };
    case 'DELETE_USER':
      return {
        ...state,
        list: state.list.filter(user => user.id !== action.id),
      };
    default:
      return state
  }
}

export default users;
