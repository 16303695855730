import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { DebounceInput } from 'react-debounce-input'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUsers, getRides, getCars, addEditRide, deleteRide, updateDriver, removeContract, sendSMS, sendEmail, getContractFromRide } from '../actions'
import GroupedTable from '../containers/GroupedTable/GroupedTable';
import AddEditRide from '../components/AddEditRide/AddEditRide';
import TextField from '@material-ui/core/TextField';
import ConfirmDialog from '../components/ConfirmDialog/ConfirmDialog';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import moment from 'moment';
import config from '../config';
import './Schedule.css';

class Schedule extends React.Component {

  constructor() {
    super();
    this.state = {
      open: false,
      openDelete: false,
      currentRide: null,
      currentTrack: null,
      openNotification: false,
      notification: "",
      notificationError: false,
      customer: "",
      status: "all",
      currentDate: config.IS_DEMO ? moment('2019-10-01') :  moment().startOf('day'),
    };
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.handleClickAddOpen = this.handleClickAddOpen.bind(this);
    this.handleClickEditOpen = this.handleClickEditOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDeleteOpen = this.handleDeleteOpen.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.removeContract = this.removeContract.bind(this);
    this.updateTheDriver = this.updateTheDriver.bind(this);
    this.sendTheSMS = this.sendTheSMS.bind(this);
    this.sendTheEmail = this.sendTheEmail.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
  }

  componentDidMount() {
    if(this.state.currentDate && this.state.currentDate.unix() > 0) {
      this.props.dispatchGetRides(this.state.currentDate.unix());
    }
    this.props.dispatchGetUsers();
    this.props.dispatchGetCars();
  }

  closeNotification() {
    this.setState({
      openNotification: false,
    })
  }

  save(id, ride, data) {
    this.props.dispatchAddEditRide(id, ride, data, (d) => {

      let currentRide = null;
      if(ride) {
        currentRide = this.props.rides.find(r => r.id === ride.id);
      } else { // newly added :)
        currentRide = d;
      }

      this.setState({
        currentRide,
        currentTrack: null
      });
      const { currentDate, customer, status } = this.state;
      this.props.dispatchGetRides(currentDate.unix(), customer, status);
    });
  }

  handleClickAddOpen() {
    this.setState({
      open: true,
      currentRide: null,
      currentTrack: null
    });
  }

  handleClickEditOpen(track) {
    this.setState({
      open: true,
      currentRide: this.props.rides.find(r => r.id === track.ride),
      currentTrack: track
    });
  }

  handleClose() {
    this.setState({
      open: false,
      currentRide: null,
      currentTrack: null,
    });
  }

  handleDateChange(date) {
    const { customer, status } = this.state;
    const day = moment(new Date(date));
    this.setState({
      currentDate: day,
    });
    this.props.dispatchGetRides(day.unix(), customer, status);
  }
  
  handleCustomerChange(ev)  {
    const { target } = ev;
    const { currentDate, status } = this.state;
    this.setState({
      customer: target.value,
    });
    this.props.dispatchGetRides(currentDate.unix(), target.value, status)
  }

  handleStatusChange(ev)  {
    const { target } = ev;
    const { currentDate, customer } = this.state;
    this.setState({
      status: target.value,
    });
    this.props.dispatchGetRides(currentDate.unix(), customer, target.value)
  }

  handleDeleteOpen(track) {
    this.setState({
      openDelete: true,
      currentTrack: track
    });
  }

  handleDeleteClose() {
    this.setState({
      openDelete: false,
    });
  }

  delete() {
    const track = this.state.currentTrack;
    this.props.dispatchDeleteRide(track, (data) => {
      const currentRide = this.props.rides.find(r => r.id === track.ride);
      this.setState({
        currentRide,
        currentTrack: null
      });
    });
  }

  removeContract(id) {
    this.props.dispatchRemoveContract(id);
  }

  updateTheDriver(track, driverId) {
    this.props.dispatchUpdateDriver(track, driverId);
  }

  sendTheSMS(item, tel, message) {
    this.props.dispatchSendSMS(item, tel, message, () => {
      this.setState({
        openNotification: true,
        notification: "A SMS has been sent to the customer!",
        notificationError: false,
      })
    }, () => {
      this.setState({
        openNotification: true,
        notification: "The sms was not send, something went wrong.",
        notificationError: true,
      })
    });
  }

  sendTheEmail(item, email, message) {
    this.props.dispatchSendEmail(item, email, message, () => {
      this.setState({
        openNotification: true,
        notification: "A confirmation email has been sent to the customer!",
        notificationError: false,
      })
    }, () => {
      this.setState({
        openNotification: true,
        notification: "The email was not send, something went wrong.",
        notificationError: true,
      })
    });
  }

  render(){

    const columns = [
      { dataKey: "validated", title: "Val" },
      { dataKey: "driver", title: "Driver" },
      { dataKey: "pickupd", title: "Pickup Date" },
      { dataKey: "ht", title: "H/T" },
      { dataKey: "name", title: "Name" },
      //{ dataKey: "email", title: "E-mail" },
      //{ dataKey: "tel", title: "Tel." },
      { dataKey: "pickupa", title: "Pickup Address" },
      { dataKey: "dropa", title: "Drop Address" },
      //{ dataKey: "traveltime", title: "Travel time (min)" },
      { dataKey: "numberp", title: "#" },
      { dataKey: "price", title: "Price" },
      { dataKey: "bf", title: "B/F" },
      { dataKey: "details", title: "Details" },
      { dataKey: "htdate", title: "H/T Date" },
      { dataKey: "paid", title: "Paid" },
    ];

    // Expand the tracks objects of each ride.
    let rides = [];
    const propsrides = this.props.rides;
    if(propsrides) {
      for (const ride of propsrides) {
        const tracks = ride.tracks;
        for (const track of tracks) {
          track.ride = ride.id;
          track.pickupd = moment(track.pickupd);
          track.htdate = moment(track.htdate);
          rides.push(track);
        }
      }
      rides = rides.sort((a, b) => moment(a.pickupd) - moment(b.pickupd));
    }

    return <div>
      <Grid container spacing={0}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            id="mui-pickers-date"
            className="paddingRight"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            ampm={false}
            label="Pickup date"
            value={this.state.currentDate}
            onChange={date => this.handleDateChange(date)}
          />
        </MuiPickersUtilsProvider>

        <DebounceInput
          placeholder="Customer Name"
          className="paddingRight"
          variant="outlined"
          minLength={1}
          element={TextField}
          debounceTimeout={500}
          onChange={event => this.handleCustomerChange(event)}
        />

        <Select 
            required
            className="paddingRight"
            value={this.state.status}
            onChange={event => this.handleStatusChange(event)}
            variant="outlined"
            label="Status"
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="approved">Approved</MenuItem>
          </Select>
          
        <Paper className="fullWidth">
          <GroupedTable
            role={this.props.role}
            onEdit={this.handleClickEditOpen}
            onDelete={this.handleDeleteOpen}
            drivers={this.props.drivers}
            columns={columns}
            filterByDate={this.state.currentDate}
            rows={rides}
            rides={this.props.rides}
            updateDriver={this.updateTheDriver}
            sendSMS={this.sendTheSMS}
            sendEmail={this.sendTheEmail}
            openContract={this.props.dispatchGetContractFromRide}/>
        </Paper>
      </Grid>
      { (this.props.role === "admin" || this.props.role === "planner") && <Tooltip title="Add a new ride" placement="top">
        <Fab color="primary" aria-label="Add" className="add" onClick={this.handleClickAddOpen}>
          <AddIcon/>
        </Fab>
      </Tooltip> }
      <AddEditRide
        drivers={this.props.drivers}
        role={this.props.role}
        saveRide={this.save}
        currentTrack={this.state.currentTrack}
        currentRide={this.state.currentRide}
        open={this.state.open}
        removeContract={this.removeContract}
        removeTrack={this.handleDeleteOpen}
        onClose={this.handleClose}
        showTable={true}/>
      <ConfirmDialog
        onSubmit={this.delete}
        title="Delete ride"
        message="You're about to delete this ride. Are you sure?"
        canNotSubmit={false}
        editable={false}
        button="delete"
        open={this.state.openDelete}
        handleClose={this.handleDeleteClose} />
      <Snackbar
        className={clsx({"big": true, "errorr": this.state.notificationError})}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.state.openNotification}
        autoHideDuration={1000}
        onClose={() => {}}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{this.state.notification}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.closeNotification}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </div>
  }
}

const mapStateToProps = (state, ownProps) => ({
  drivers: state.users.list ? state.users.list.filter(u => u.username !== 'admin') : [],
  rides: state.rides.list,
  role: state.auth.role
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchAddEditRide: (id, ride, data, callback) => dispatch(addEditRide(id, ride, data, callback)),
  dispatchDeleteRide: (track, callback) => dispatch(deleteRide(track, callback)),
  dispatchRemoveContract: (id) => dispatch(removeContract(id)),
  dispatchGetUsers: () => dispatch(getUsers()),
  dispatchGetCars: () => dispatch(getCars()),
  dispatchGetRides: (timestamp, customer, status) => dispatch(getRides(timestamp, customer, status)),
  dispatchSendSMS: (track, tel, message, onSuccess, onError) => dispatch(sendSMS(track, tel, message, onSuccess, onError)),
  dispatchSendEmail:  (track, email, message, onSuccess, onError) => dispatch(sendEmail(track, email, message, onSuccess, onError)),
  dispatchUpdateDriver: (track, driverId, callback) => dispatch(updateDriver(track, driverId, callback)),
  dispatchGetContractFromRide: (id, callback) => dispatch(getContractFromRide(id, callback)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Schedule));
