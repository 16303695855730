import { doGetHoursByUser, doGetCashByUser, doGetPrivateInvoices } from '../api/reports';

export const getHoursByUser = (fromDay, toDay) => {
  return function (dispatch) {
    doGetHoursByUser(fromDay, toDay, (data) => {
      // get hours by user
      dispatch({
        type: 'GET_HOURS_BY_USER',
        hoursByUser: data.data,
      });
    }, (error) => {
      //console.log("something went wrong :(")
    });
  }
}

export const getCashByUser = (fromDay, toDay) => {
  return function (dispatch) {
    doGetCashByUser(fromDay, toDay, (data) => {
      // get cash by user
      dispatch({
        type: 'GET_CASH_BY_USER',
        cashByUser: data.data,
      });
    }, (error) => {
      //console.log("something went wrong :(")
    });
  }
}

export const getPrivateInvoice = (fromDay, toDay) => {
  return function (dispatch) {
    doGetPrivateInvoices(fromDay, toDay, (data) => {
      // get cash by user
      dispatch({
        type: 'GET_PRIVATE_INVOICES',
        privateInvoice: data.data,
      });
    }, (error) => {
      //console.log("something went wrong :(")
    });
  }
}
