import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import SignatureCanvas from 'react-signature-canvas';
import { saveContract } from '../../actions'
import moment from 'moment';
import './RideContract.css';

class RideContract extends React.Component {
  constructor(){
    super();
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.initialState = {};
    this.state = this.initialState;
    this.canvas = React.createRef();
    this.image = React.createRef();
    this.sigCanvas = null;
    this.width = 889;
    this.height = 1259;

    this.state = {
      track: null,
      contract: null
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { track, contract } = this.props;
    if(prevProps.track !== track) {
      this.setState({
        track
      });
    }
    if(prevProps.contract !== contract) {
      this.setState({
        contract
      });
    }
  }

  reFill() {
    const { track } = this.state;
    const { rides } = this.props;
    let ride = null;
    if(track) {
      ride = rides.find(r => r.id === track.ride);
    }

    const canvas = this.sigCanvas.getCanvas();
    var ctx = canvas.getContext("2d");

    var img = new Image();
    img.onload = () => {
      // Draw image
      ctx.drawImage(img, 0, 0);

      // Fill-in address
      const startAddressX = 480;
      const startAddressY = 230;
      ctx.font = "15px Arial";

      ctx.fillText(moment().format("YYYY"), 170 , 156);

      const { track } = this.state;
      ctx.fillText(track.name, startAddressX, startAddressY);
      if(track.ht === "H") {
        ctx.fillText(track.pickupa, startAddressX, startAddressY + 20);
      } else {
        ctx.fillText(track.dropa, startAddressX, startAddressY + 20);
      }
      ctx.fillText(track.tel, startAddressX, startAddressY + 40);
      ctx.fillText(track.email, startAddressX, startAddressY + 60);

      if(track.bf === 'f') {
        const startX = 545;
        const startY = 930;
        ctx.fillRect(startX, startY, 20, 15);
      }

      ctx.fillText("9000 Gent", 200 , 1035);
      ctx.fillText(moment(track.pickupd).format("YYYY-MM-DD"), 420 , 1035);

      if(ride.tracks.length === 1) {
        // Start time
        ctx.fillText(moment(track.pickupd).format("YYYY-MM-DD"), 340 , 705);
        ctx.fillText(moment(track.pickupd).format("HH:mm"), 520 , 705);

        // End time
        const end = moment(track.pickupd).add(parseInt(track.traveltime), 'minutes');
        ctx.fillText(moment(end).format("YYYY-MM-DD"), 340 , 725);
        ctx.fillText(moment(end).format("HH:mm"), 520 , 725);
        ctx.fillText(track.price, 350 , 940);

      } else {
        let y = 790;
        let price = 0;
        ride.tracks.sort((a, b) => moment(a.pickupd) - moment(b.pickupd)).map(t => {
          // start time
          ctx.fillText(moment(t.pickupd).format("YYYY-MM-DD"), 280 , y);
          ctx.fillText(moment(t.pickupd).format("HH:mm"), 475 , y);
          // end time
          const end = moment(t.pickupd).add(parseInt(t.traveltime), 'minutes');
          ctx.fillText(moment(end).format("HH:mm"), 600 , y);
          // price
          ctx.fillText("€"+ t.price, 730 , y);
          y += 18;
          price += parseInt(t.price);
          return null;
        });

        ctx.fillText("€"+price, 350 , 940);
      }
    }
    img.src = "/contract.png";
  }

  handleClose() {
    this.props.onClose();
  }

  handleSubmit(e) {
    e.preventDefault();
    const { track } = this.state;
    const image = this.sigCanvas.toDataURL();
    this.props.dispatchSaveContract(track.ride, image, this.props.refresh);
    this.props.onClose();
  }

  render() {

      const { track, contract } = this.state;
      const { rides } = this.props;
      let ride = null;
      if(track) {
        ride = rides.find(r => r.id === track.ride);
      }
      return <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          maxWidth="md">
          <form onSubmit={this.handleSubmit}>
            <DialogContent>
              { ride && ride.contract === "false" &&
                <SignatureCanvas
                  penColor='black'
                  minWidth={0.8}
                  maxWidth={0.8}
                  dotSize={0.8}
                  ref={(ref) => {
                    this.sigCanvas = ref
                    if(this.sigCanvas) {
                      this.reFill();
                    }
                  }}
                  clearOnResize={false}
                  canvasProps={{width: this.width, height: this.height, className: 'sigCanvas'}}
                /> }

              { ride && ride.contract === "true" && <img alt="contract" src={contract}/> }
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.handleClose()} color="primary">
                Cancel
              </Button>

              { ride && ride.contract === "false" &&
                <Button color="primary" type="submit">
                  Save
                </Button>

              }
            </DialogActions>
          </form>
        </Dialog>
      </div>;
  }
}

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchSaveContract: (id, image, callback) => dispatch(saveContract(id, image, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RideContract);
