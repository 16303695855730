import React from "react";
import TableCell from "@material-ui/core/TableCell";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import './SelectField.css';

class SelectField extends React.Component {
  constructor(){
    super();
    this.state = {
      editable: false
    }
    this.openEdit = this.openEdit.bind(this);
    this.closeEdit = this.closeEdit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  openEdit() {
    this.setState({
      editable: true
    })
  }

  closeEdit() {
    this.setState({
      editable: false
    })
  }

  onChange(e) {
    const value = e.target.value;
    const { item } = this.props;
    this.props.update(item, value);
    this.closeEdit();
  }

  render() {
    const { dataKey, data, list } = this.props;
    const { editable } = this.state;

    if(!editable) {
      if(data) {
        if ( this.props.canEdit ) {
          return <TableCell
              onClick={this.openEdit}
              key={dataKey}
              style={{backgroundColor: data.color}}
              className="pointer">
                {data.username}
            </TableCell>
        } else {
          return <TableCell
              key={dataKey}
              style={{backgroundColor: data.color}}
              className="pointer">
                {data.username}
            </TableCell>
        }
      } else {
        return <TableCell
            onClick={this.openEdit}
            key={dataKey}
            className="pointer">
              -
          </TableCell>
      }
    } else {

      return <td><Select onChange={this.onChange}
        value={data ? data.id : "-"}
        required
        margin="dense"
        fullWidth
        className="driver-select"
        inputProps={{
          name: 'list',
          id: 'list-simple',
        }}
      >
        <MenuItem
          key="-"
          value="-"
          style={{backgroundColor: "white"}}
        >
          -
        </MenuItem> ))}
        { list.map(v => (
          <MenuItem
            key={v.id}
            value={v.id}
            style={{backgroundColor: v.color}}
          >
            {v.username}
          </MenuItem> ))}
      </Select></td>
    }
  }
}

export default SelectField;
