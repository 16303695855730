import API from './api';

export function doSendSMS(track, telephone, message, onSuccess, onError) {
  API.post(`sms`, {
    track,
    telephone,
    message
  })
  .then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}

export function doSendEmail(track, email, message, onSuccess, onError) {
  API.post(`email`, {
    track,
    email,
    message
  })
  .then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}
