import { doAddUser, doEditUser, doDeleteUser, doGetUsers } from '../api/user';

export const getUsers = () => {
  return function (dispatch) {
    doGetUsers((users) => {
      // get users
      dispatch({
        type: 'GET_USERS',
        users: users.data
      });
    }, (error) => {
      //console.log("something went wrong :(")
    });
  }
}

export const addEditUser = (id, data) => {
  return function (dispatch) {
    // We need to add a new user.
    if(id === -1) {
      doAddUser(data, (d) => {
        // add user to the existing list.
        dispatch({
          type: 'ADD_USER',
          user: d
        });
      }, (error) => {
        //console.log("something went wrong :(")
      });
    } else {
      doEditUser(id, data, (d) => {
        // edit user in the list.
        dispatch({
          type: 'EDIT_USER',
          id,
          user: d
        });
      }, (error) => {
        //console.log("something went wrong :(")
      });
    }
  }
}

export const deleteUser = (id) => {
  return function (dispatch) {
    doDeleteUser(id, (d) => {
      // add user to the existing list.
      dispatch({
        type: 'DELETE_USER',
        id
      });
    }, (error) => {
      //console.log("something went wrong :(")
    });
  }
}
