import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PeopleIcon from '@material-ui/icons/People';
import TodayIcon from '@material-ui/icons/Today';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import './Navigation.css';

class Navigation extends React.Component {
  render() {
      const { auth } = this.props;
      return <List>
        <NavLink to={'/'} className="nav-link" exact activeClassName="activeRoute">
          <ListItem button>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Overview" />
          </ListItem>
        </NavLink>
        <NavLink to={'/schedule'} className="nav-link" activeClassName="activeRoute">
          <ListItem button>
            <ListItemIcon>
              <TodayIcon />
            </ListItemIcon>
            <ListItemText primary="Schedule" />
          </ListItem>
        </NavLink> 
        { auth.role === 'admin' &&  <div>
            <NavLink to={'/reports'} className="nav-link" activeClassName="activeRoute">
              <ListItem button>
                <ListItemIcon>
                  <AccountBalanceIcon />
                </ListItemIcon>
                <ListItemText primary="Reports" />
              </ListItem>
            </NavLink>
            <NavLink to={'/users'} className="nav-link" activeClassName="activeRoute">
              <ListItem button>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItem>
            </NavLink>
            <NavLink to={'/cars'} className="nav-link" activeClassName="activeRoute">
              <ListItem button>
                <ListItemIcon>
                  <AirportShuttleIcon />
                </ListItemIcon>
                <ListItemText primary="Cars" />
              </ListItem>
            </NavLink>

            { /*<NavLink to="" className="nav-link inactive-menu" activeClassName="activeRoute">
              <ListItem button>
                <ListItemIcon>
                  <NotificationsIcon />
                </ListItemIcon>
                <ListItemText primary="Notifications" />
              </ListItem>
            </NavLink> */ }
          </div>
        }
      </List>;
  }
}

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
