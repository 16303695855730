const reports = (state = {
  hoursByUser: [],
  cashByUser: [],
  privateInvoice: [],
}, action) => {
  switch (action.type) {
    case 'GET_HOURS_BY_USER':
      return {
        ...state,
        hoursByUser: action.hoursByUser
      };
    case 'GET_CASH_BY_USER':
      return {
        ...state,
        cashByUser: action.cashByUser
      };
    case 'GET_PRIVATE_INVOICES':
      return {
        ...state,
        privateInvoice: action.privateInvoice
      };
    default:
      return state
  }
}

export default reports;
