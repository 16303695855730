const cars = (state = {
  list: []
}, action) => {
  switch (action.type) {
    case 'GET_CARS':
      return {
        ...state,
        list: action.cars
      };
    case 'ADD_CAR':
      return {
        ...state,
        list: [
          ...state.list,
          action.car
        ]
      };
    case 'EDIT_CAR':
      return {
        ...state,
        list: state.list.map((car, i) => car.id === action.id ? action.car : car)
      };
    case 'DELETE_CAR':
      return {
        ...state,
        list: state.list.filter(car => car.id !== action.id),
      };
    default:
      return state
  }
}

export default cars;
