import exp from 'constants';
import {
  doAddRide,
  doGetContractFromRide,
  doSaveContract,
  doEditTrack,
  doAddTrack,
  doUpdateDriver,
  doDeleteRide,
  doGetRides,
  doGetFilteredRides,
  doGetRidesByDay,
  doSendChiron
} from '../api/rides';

export const getRides = (timestamp, customer, status) => {
  return function (dispatch) {
    doGetRides(timestamp, customer, status, (rides) => {
      // get rides
      dispatch({
        type: 'GET_RIDES',
        rides: rides.data
      });
    }, (error) => {
      //console.log("something went wrong :(")
    });
  }
}

export const getRidesByDay = (fromDay, toDay) => {
  return function (dispatch) {
    doGetRidesByDay(fromDay, toDay, (rides) => {
      // get rides
      dispatch({
        type: 'GET_RIDES_BY_DAY',
        day: fromDay,
        rides: rides.data
      });
    }, (error) => {
      //console.log("something went wrong :(")
    });
  }
}

export const getFilteredRides = () => {
  return function (dispatch) {
    doGetFilteredRides((rides) => {
      // get rides
      dispatch({
        type: 'GET_FILTERED_RIDES',
        filtered_rides: rides.data
      });
    }, (error) => {
      //console.log("something went wrong :(")
    }
    );
  }
}


export const sendChiron = (id, track, data, callback) => {
  return function (dispatch) {
    doSendChiron(id, data, (d) => {
      // add ride to the existing list.
      dispatch({
        type: 'SEND_CHIRON',
        track,
        data,
      });

      alert("Ride succesfully send to Chiron.")

      if(callback) {
        callback(d);
      }
    }, (error) => {
      alert("Something went wrong while sending to Chiron.")
    });
  }
}

export const addEditRide = (id, ride, data, callback) => {
  return function (dispatch) {
    // We need to add a new ride.

    if(id === -1) {
      if(ride === null) {
        doAddRide(data, (d) => {
          // add ride to the existing list.
          dispatch({
            type: 'ADD_RIDE',
            ride: d
          });

          if(callback) {
            callback(d);
          }
        }, (error) => {
          //console.log("something went wrong :(")
        });
      } else {
        // adding a new track
        doAddTrack(ride, data, (t) => {
          // add ride to the existing list.
          dispatch({
            type: 'ADD_TRACK',
            ride: ride,
            track: t
          });

          if(callback) {
            callback();
          }
        }, (error) => {
          //console.log("something went wrong :(")
        });
      }
    } else {
      doEditTrack(id, data, (t) => {
        // edit ride in the list.
        dispatch({
          type: 'EDIT_TRACK',
          id,
          track: t
        });
        if(callback) {
          callback();
        }
      }, (error) => {
        //console.log("something went wrong :(")
      });
    }
  }
}

export const deleteRide = (track, callback) => {
  return function (dispatch) {
    doDeleteRide(track.id, (d) => {
      // delete ride from the existing list.
      dispatch({
        type: 'DELETE_RIDE',
        track
      });

      if(callback) {
        callback();
      }
    }, (error) => {
      //console.log("something went wrong :(")
    });
  }
}

export const saveContract = (id, image, callback) => {
  return function (dispatch) {
    doSaveContract(id, image, (d) => {
      dispatch({
        type: 'SAVE_CONTRACT',
        id,
        contract: "true",
      });
      if(callback) {
        callback();
      }
    }, (error) => {
      //console.log("something went wrong :(")
    });
  }
}

export const getContractFromRide = (id, callback) => {
  return function (dispatch) {
    doGetContractFromRide(id, (r) => {
      dispatch({
        type: 'GET_CONTRACT',
        id,
        contract: r.data.contract,
      });
      if(callback) {
        callback(r.data.contract);
      }
    }, (error) => {
      //console.log("something went wrong :(")
    });
  }
}

export const removeContract = (id, callback) => {
  return function (dispatch) {
    doSaveContract(id, "", (d) => {
      // edit ride in the list.
      dispatch({
        type: 'SAVE_CONTRACT',
        id,
        contract: "false",
      });

      if(callback) {
        callback();
      }
    }, (error) => {
      //console.log("something went wrong :(")
    });
  }
}

export const updateDriver = (track, driver, callback) => {
  return function (dispatch) {
    doUpdateDriver(track.id, driver, (d) => {
      // delete ride from the existing list.
      dispatch({
        type: 'UPDATE_DRIVER',
        track,
        driver,
      });
      if(callback) {
        callback();
      }
    }, (error) => {
      //console.log("something went wrong :(")
    });
  }
}
