import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { saveContract } from '../../actions'
import moment from 'moment';
import './CreateInvoice.css';

class CreateInvoice extends React.Component {
  constructor(){
    super();
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.initialState = {};
    this.state = this.initialState;
    this.state = {
      track: null,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { track } = this.props;
    if(prevProps.track !== track) {
      this.setState({
        track
      });
    }
  }

  handleClose() {
    this.props.onClose();
  }

  handleSubmit(e) {
    e.preventDefault();
    const { track } = this.state;
    //this.props.dispatchSaveContract(track.ride, image, this.props.refresh);
    this.props.onClose();
  }

  render() {

      const { track } = this.state;
      const { rides } = this.props;
      let ride = null;
      if(track) {
        ride = rides.find(r => r.id === track.ride);
      }
      return <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          maxWidth="md">
          <form onSubmit={this.handleSubmit}>

            <DialogTitle id="alert-dialog-title">Create an invoice</DialogTitle>
            <DialogContent>
            { ride && ride.tracks.length > 0 && <div>
              <div>Customer Name: {ride.tracks[0].name}</div>
              <div>Email: {ride.tracks[0].email}</div>
              <div>VAT number: {ride.tracks[0].bookNumber}</div>
            </div>}

              { ride && ride.tracks.length > 0 && ride.tracks.map(t => <div>
                  <p>From: {t.pickupa} / To: {t.dropa} </p>
                  <p>Price: {t.price}EUR</p>
                </div>)
              }
              
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.handleClose()} color="primary">
                Cancel
              </Button>

              {  true &&
                <Button color="primary" type="submit">
                  Create invoice
                </Button>

              }
            </DialogActions>
          </form>
        </Dialog>
      </div>;
  }
}

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchSaveContract: (id, image, callback) => dispatch(saveContract(id, image, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateInvoice);
