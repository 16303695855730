import API from './api';

export function doAddUser(data, onSuccess, onError) {
  API.post(`users`, {
    ...data
  })
  .then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}

export function doEditUser(id, data, onSuccess, onError) {
  API.put(`users`, {
    id,
    ...data
  })
  .then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}

export function doDeleteUser(id, onSuccess, onError) {
  API.delete(`users/${id}`)
  .then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}

export function doGetUsers(onSuccess, onError) {
  API.get(`users`)
  .then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}
