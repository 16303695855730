import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import RideContract from '../RideContract/RideContract';
import CreateInvoice from '../CreateInvoice/CreateInvoice';
import IconButton from "@material-ui/core/IconButton";
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import GroupedTableRow from './GroupedTableRow';
import './GroupedTable.css';

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => (a[orderBy] > b[orderBy] ? -1 : 1)
    : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
}

class GroupedTable extends React.Component {

  constructor() {
    super();
    this.state = {
      groupBy: "pickupd",
      sortBy: "pickupd",
      sortOrder: "asc",
      expandedGroups: [],
      openContract: false,
      track: null,
      tel: null,
      email: null,
      message: null,
      messageEN: null,
      invoice: null,
      openSendEmail: false,
      openSendSMS: false,
      openCreateInvoice: false,
      contact: null
    };

    this.openContract = this.openContract.bind(this);
    this.closeContract = this.closeContract.bind(this);

    this.openSendSMS = this.openSendSMS.bind(this);
    this.closeSendSMS = this.closeSendSMS.bind(this);
    this.sendTheSMS = this.sendTheSMS.bind(this);

    this.openSendEmail = this.openSendEmail.bind(this);
    this.closeSendEmail = this.closeSendEmail.bind(this);
    this.sendTheEmail = this.sendTheEmail.bind(this);

    this.openCreateInvoice = this.openCreateInvoice.bind(this);
    this.closeCreateInvoice = this.closeCreateInvoice.bind(this);
  }

  getColumnData = columns => {
    return columns;
  };

  getGroupedData = rows => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const groupedData = rows.reduce((acc, item) => {
      let key = new Date(item[this.state.groupBy]).toLocaleDateString("nl-BE", options);
      let groupData = acc[key] || [];
      acc[key] = groupData.concat([item]);
      return acc;
    }, {});

    const expandedGroups = {};
    const { sortBy, sortOrder } = this.state;
    Object.keys(groupedData).forEach(item => {
      expandedGroups[item] = this.state.expandedGroups.indexOf(item) !== -1;
      groupedData[item] = groupedData[item].sort(getSorting(sortOrder, sortBy));
    });

    this.groups = expandedGroups;
    return groupedData;
  };

  handleRequestSort = property => {
    const sortBy = property;
    let sortOrder = "desc";

    if (this.state.sortBy === property && this.state.sortOrder === "desc") {
      sortOrder = "asc";
    }
    this.setState({ sortOrder, sortBy });
  };

  expandRow = groupVal => {
    const curr = this.groups[groupVal];
    let expandedGroups = this.state.expandedGroups;
    if (curr) {
      expandedGroups = expandedGroups.filter(item => item !== groupVal);
    } else {
      if (expandedGroups.indexOf(groupVal) === -1) {
        //Maintain all open groups ever!!
        expandedGroups = expandedGroups.concat([groupVal]);
      }
    }
    this.setState({ expandedGroups });
  };

  componentDidUpdate(prevProps) {
    const { drivers } = this.props;
    if(prevProps.drivers !== drivers) {
      this.setState({
        drivers
      });
    }
  }

  openContract(row){
    this.props.openContract(row.ride, (contract) => {
      this.setState({
        ride: row,
        openContract: true,
        contract
      });
    });
  }

  closeContract(){
    this.setState({
      openContract: false,
      contract: null
    });
  }

  openSendSMS(track, tel, message){
    this.setState({
      track,
      tel,
      message,
      openSendSMS: true,
    });
  }

  closeSendSMS(track, tel, message){
    this.setState({
      track: null,
      tel: null,
      message: null,
      openSendSMS: false,
    });
  }

  sendTheSMS(message){
    const { sendSMS } = this.props;
    const { track, tel } = this.state;
    message = message.replace(/<br\/>/g, "")
    sendSMS(track, tel, message);
  }

  openCreateInvoice(track){
    this.setState({
      track,
      openCreateInvoice: true,
    });
  }

  closeCreateInvoice(track, invoice){
    this.setState({
      track: null,
      invoice: null,
      openCreateInvoice: false,
    });
  }


  openSendEmail(track, email, message, messageEN){
    this.setState({
      track,
      email,
      message,
      messageEN,
      openSendEmail: true,
    });
  }

  closeSendEmail(track, tel, message){
    this.setState({
      track: null,
      tel: null,
      message: null,
      messageEN: null,
      openSendEmail: false,
    });
  }

  sendTheEmail(message){ // message can be modified
    const { sendEmail } = this.props;
    const { track, email } = this.state;
    sendEmail(track, email, message);
  }

  render() {
    const { rows, columns, filterByDate } = this.props;
    let rowsFiltered = rows;
    if(filterByDate) {
      rowsFiltered = rows.filter(r => {
        return r.pickupd >= filterByDate;
      });
    }
    let columnData = this.getColumnData(columns);
    let groupedData = this.getGroupedData(rowsFiltered);
    let { sortBy, sortOrder, contract, invoice } = this.state;

    return (
      <div>
        <RideContract
          rides={this.props.rides}
          track={this.state.ride}
          open={this.state.openContract}
          onClose={this.closeContract}
          contract={contract} />

        <CreateInvoice
          rides={this.props.rides}
          track={this.state.track}
          open={this.state.openCreateInvoice}
          onClose={this.closeCreateInvoice}
          contract={invoice} />

        <Table className="schedule">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {columnData.map(item => (
                <TableCell key={item.dataKey}>
                  <TableSortLabel
                    active={sortBy === item.dataKey}
                    direction={sortOrder}
                    onClick={this.handleRequestSort.bind(null, item.dataKey)}
                  >
                    {item.title}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(groupedData).map(key => {
              return (
                <React.Fragment key={key}>
                  <TableRow>
                    <TableCell
                      colSpan={columnData.length + 1}
                      style={{ fontWeight: "bold", cursor: "pointer" }}
                      onClick={this.expandRow.bind(null, key)}
                    >
                      <IconButton>
                        {this.groups[key] && <ExpandMoreIcon/>}
                        {!this.groups[key] && <ChevronRightIcon/>}
                      </IconButton>
                      <span>{key} ({groupedData[key].length})</span>
                    </TableCell>
                  </TableRow>
                  {this.groups[key] &&
                    groupedData[key].map(item => (
                      <GroupedTableRow
                        key={item.id}
                        item={item}
                        rides={this.props.rides}
                        role={this.props.role}
                        onEdit={this.props.onEdit}
                        onDelete={this.props.onDelete}
                        sendSMS={this.openSendSMS}
                        sendEmail={this.openSendEmail}
                        createInvoice={this.openCreateInvoice}
                        openContract={this.openContract}
                        columnData={columnData}
                        drivers={this.state.drivers}
                        updateDriver={this.props.updateDriver}
                      />
                    ))}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
        <ConfirmDialog
          onSubmit={this.sendTheSMS}
          canNotSubmit={this.state.tel === "" || this.state.tel === "-"}
          title={"Do you want to send a SMS reminder to " + this.state.tel + "?"}
          message={this.state.message}
          button="send"
          open={this.state.openSendSMS}
          handleClose={this.closeSendSMS} />
        <ConfirmDialog
          onSubmit={this.sendTheEmail}
          canNotSubmit={this.state.email === "" || this.state.email === "-"}
          title={"Do you want to send a confirmation email to " + this.state.email + "?"}
          message={this.state.message}
          messageEN={this.state.messageEN}
          translation={["nl", "en"]}
          button="send"
          editable={true}
          open={this.state.openSendEmail}
          handleClose={this.closeSendEmail} />
      </div>
    );
  }
}

export default GroupedTable;
