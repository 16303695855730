import {
  doSendSMS, doSendEmail
} from '../api/notifications';

export const sendSMS = (row, tel, message, onSuccess, onError) => {
  return function (dispatch) {
    doSendSMS(row, tel, message, (data) => {
      dispatch({
        type: 'SEND_SMS',
        track: row
      });
      if(onSuccess) {
        onSuccess();
      }
    }, () => {
      if(onError) {
        onError();
      }
    });
  }
}

export const sendEmail = (row, email, message, onSuccess, onError) => {
  return function (dispatch) {
    doSendEmail(row, email, message, (data) => {
      dispatch({
        type: 'SEND_EMAIL',
        track: row
      });
      if(onSuccess) {
        onSuccess();
      }
    }, () => {
      if(onError) {
        onError();
      }
    });
  }
}
