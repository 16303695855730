import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import "./Reports.css";
import moment from 'moment';
import config from '../config';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { doGetPrivateInvoiceExcel } from '../api/reports';
import { deleteUser, addEditUser, getUsers, getHoursByUser, getCashByUser, getPrivateInvoice } from '../actions'

class Reports extends React.Component {

  constructor() {
    super();
    this.state = {
      open: false,
      openDelete: false,
      currentUser: null,
      fromDay: config.IS_DEMO ? moment('2019-10-17').startOf('day').subtract(7, 'days') : moment().startOf('day').subtract(7, 'days'),
      toDay: config.IS_DEMO ? moment('2019-10-17').startOf('day') : moment().startOf('day'),
      currentReport: "hours",
    };
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.handleClickAddOpen = this.handleClickAddOpen.bind(this);
    this.handleClickEditOpen = this.handleClickEditOpen.bind(this);
    this.handleDeleteOpen = this.handleDeleteOpen.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleFromDateChange = this.handleFromDateChange.bind(this);
    this.handleToDateChange = this.handleToDateChange.bind(this);
    this.changeReport = this.changeReport.bind(this);
  }

  componentDidMount() {
    const { fromDay, toDay } = this.state;
    this.props.dispatchGetUsers();
    this.props.dispatchGetHoursByUser(fromDay.unix(), toDay.unix());
  }

  save(id, data) {
    this.props.dispatchAddEditUser(id, data);
  }

  delete() {
    const { id } = this.state.currentUser;
    this.props.dispatchDeleteUser(id);
  }

  handleClickAddOpen() {
    this.setState({
      open: true,
      currentUser: null
    });
  }

  handleClickEditOpen(user) {
    this.setState({
      open: true,
      currentUser: user
    });
  }

  handleClose() {
    this.setState({
      open: false
    });
  }

  handleDeleteOpen(user) {
    this.setState({
      openDelete: true,
      currentUser: user
    });
  }

  handleDeleteClose() {
    this.setState({
      openDelete: false,
    });
  }

  handleFromDateChange(date) {
    const day = moment(new Date(date));
    this.setState({
      fromDay: day,
    });
    const { toDay, currentReport } = this.state;
    if(currentReport === "hours") {
    this.props.dispatchGetHoursByUser(day.unix(), toDay.unix());
    } else if(currentReport === "cash") {
      this.props.dispatchGetCashByUser(day.unix(), toDay.unix());
    } else if(currentReport === "private") {
      this.props.dispatchGetPrivateInvoice(day.unix(), toDay.unix());
    }
  }

  handleToDateChange(date) {
    const day = moment(new Date(date));
    this.setState({
      toDay: day,
    });
    const { fromDay, currentReport } = this.state;
    if(currentReport === "hours") {
      this.props.dispatchGetHoursByUser(fromDay.unix(), day.unix());
    } else if(currentReport === "cash") {
      this.props.dispatchGetCashByUser(fromDay.unix(), day.unix());
    } else if(currentReport === "private") {
      this.props.dispatchGetPrivateInvoice(fromDay.unix(), day.unix());
    }
  }

  changeReport(report){
    const { fromDay, toDay, currentReport } = this.state;
    this.setState({
      currentReport: report,
    });

    if(currentReport !== report) {
      if(report === "hours") {
        this.props.dispatchGetHoursByUser(fromDay.unix(), toDay.unix());
      } else if(report === "cash") {
        this.props.dispatchGetCashByUser(fromDay.unix(), toDay.unix());
      } else if(report === "private") {
        this.props.dispatchGetPrivateInvoice(fromDay.unix(), toDay.unix());
      }
    }
  }

  render(){
    const { currentReport } = this.state;

    return <div className="reports">
      <Grid container spacing={0}>
        <Typography variant="h5" component="h3">
          Reports
        </Typography>
        <br/><br/>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} lg={3}>
            <Paper>
              <MenuList>
                <MenuItem onClick={() => this.changeReport("hours")} selected={currentReport === "hours"}>Hours by driver</MenuItem>
                <MenuItem onClick={() => this.changeReport("cash")} selected={currentReport === "cash"}>Cash by driver</MenuItem>
                <MenuItem onClick={() => this.changeReport("private")} selected={currentReport === "private"}>Private Invoices</MenuItem>
              </MenuList>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9} lg={9}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="mui-pickers-date"
                className="paddingRight"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                ampm={false}
                label="Start date"
                value={this.state.fromDay}
                onChange={date => this.handleFromDateChange(date)}
              />
              <KeyboardDatePicker
                id="mui-pickers-date"
                className="paddingRight"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                ampm={false}
                label="End date"
                value={this.state.toDay}
                onChange={date => this.handleToDateChange(date)}
              />
            </MuiPickersUtilsProvider>
            { currentReport === "hours" && <Paper style={{padding: "10px"}}>
              <Typography variant="h6" component="h4">
                Hours by driver
              </Typography>
              <br/>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Driver</TableCell>
                  </TableRow>
                </TableHead>
                  { this.props.drivers && this.props.hoursByUser && this.props.hoursByUser.map(d => {
                    const driver = this.props.drivers.find(u => u.id === d.driver)
                    const totalHours = d.performance.reduce((total, v) => {
                      total += v.minutes;
                      return total
                    }, 0);
                    if(driver) {
                      return <TableBody key={d.driver}>
                        <TableRow className="reporting-border" style={{backgroundColor: "rgb(255, 181, 9)"}}>
                          <TableCell style={{color: "white"}}>{driver.username}</TableCell>
                          <TableCell style={{color: "white"}}>{d.performance.length} days</TableCell>
                          <TableCell style={{color: "white"}}>{ totalHours }min ({ totalHours/60 }h)</TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>

                            { d.performance.sort((a, b) => a.timestamp - b.timestamp).map(p => {
                              return <TableRow key={p.day} colSpan={2}>
                                    <TableCell style={{border: "none"}}></TableCell>
                                    <TableCell style={{minWidth: "150px"}}>{p.day}</TableCell>
                                    <TableCell style={{minWidth: "100px"}}>{p.minutes}min</TableCell>
                                    <TableCell style={{minWidth: "500px"}}>
                                      <Table>
                                        <TableBody>
                                          { p.rides.map(rs => {
                                            return <TableRow key={rs.id}>
                                                <TableCell style={{border: "none"}}>{rs.customer}</TableCell>
                                                <TableCell style={{border: "none"}}>{rs.pickupa}</TableCell>
                                                <TableCell style={{border: "none"}}>{rs.dropa}</TableCell>
                                                <TableCell style={{border: "none"}}>{rs.minutes}min</TableCell>
                                            </TableRow>
                                          })}
                                        </TableBody>
                                      </Table>
                                    </TableCell>
                                </TableRow>
                            })}
                      </TableBody>
                    }
                    return "";
                  }) }
              </Table>
            </Paper> }


            { currentReport === "cash" && <Paper style={{padding: "10px"}}>
              <Typography variant="h6" component="h4">
                Cash by driver
              </Typography>
              <br/>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Driver</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { this.props.drivers && this.props.cashByUser && this.props.cashByUser.map(d => {
                    const driver = this.props.drivers.find(u => u.id === d.driver)
                    const totalCash = d.performance.reduce((total, v) => {
                      if(v.cash > 0) {
                        total += v.cash;
                      }
                      return total
                    }, 0);
                    if(driver) {
                      return <div style={{width: '100%', display: "table"}}>
                        <TableRow className="reporting-border" style={{backgroundColor: "rgb(255, 181, 9)"}}>
                          <TableCell style={{color: "white"}}>{driver.username}</TableCell>
                          <TableCell style={{color: "white"}}>{d.performance.length} days</TableCell>
                          <TableCell style={{color: "white"}}>{ totalCash } EUR</TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>

                            { d.performance.sort((a, b) => a.timestamp - b.timestamp).map(p => {
                              return <TableRow colSpan={2}>
                                    <TableCell style={{border: "none"}}></TableCell>
                                    <TableCell style={{minWidth: "150px"}}>{p.day}</TableCell>
                                    <TableCell style={{minWidth: "100px"}}>{p.cash < 0 ? "no" : p.cash + " EUR" }</TableCell>
                                    <TableCell style={{minWidth: "500px"}}>
                                      <Table>
                                        <TableBody>
                                          { p.rides.map(rs => {
                                            return <TableRow>
                                                <TableCell style={{border: "none"}}>{rs.customer}</TableCell>
                                                <TableCell style={{border: "none"}}>{rs.pickupa}</TableCell>
                                                <TableCell style={{border: "none"}}>{rs.dropa}</TableCell>
                                                <TableCell style={{border: "none"}}>{rs.cash < 0 ? "no" : rs.cash + " EUR" }</TableCell>
                                            </TableRow>
                                          })}
                                        </TableBody>
                                      </Table>
                                    </TableCell>
                                </TableRow>
                            })}
                      </div>
                    }
                    return "";
                  }) }
                </TableBody>
              </Table>
            </Paper> }

            { currentReport === "private" && <Paper style={{padding: "10px"}}>
              <Typography variant="h6" component="h4">
                Private Invoices
              </Typography>
              <br/>
              <Button onClick={() => doGetPrivateInvoiceExcel(this.state.fromDay.unix(), this.state.toDay.unix(), () => {}, () => {})}>Download</Button>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Number</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell>Payment Method</TableCell>
                    <TableCell>Datum</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>VAT</TableCell>
                    <TableCell>Amount inc VAT</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { this.props.privateInvoice && this.props.privateInvoice.map(i => {
                      return <TableRow>
                          <TableCell>{ i.id }</TableCell>
                          <TableCell>{ i.customer }</TableCell>
                          <TableCell>{ i.paymentmethod }</TableCell>
                          <TableCell>{ i.pickupd + " " + i.pickupt }</TableCell>
                          <TableCell>{ i.amountReceived - (i.amountReceived * 6 / 100) }</TableCell>
                          <TableCell>{ i.amountReceived * 6 / 100 }</TableCell>
                          <TableCell>{ i.amountReceived }</TableCell>
                        </TableRow>
                  }) }
                </TableBody>
              </Table>
            </Paper> }

          </Grid>
        </Grid>
      </Grid>
    </div>
  }
}

const mapStateToProps = (state, ownProps) => ({
  drivers: state.users.list ? state.users.list.filter(u => u.username !== 'admin') : [],
  hoursByUser: state.reports.hoursByUser,
  cashByUser: state.reports.cashByUser,
  privateInvoice: state.reports.privateInvoice,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchAddEditUser: (id, data) => dispatch(addEditUser(id, data)),
  dispatchDeleteUser: (id) => dispatch(deleteUser(id)),
  dispatchGetUsers: () => dispatch(getUsers()),
  dispatchGetHoursByUser: (fromDay, toDay) => dispatch(getHoursByUser(fromDay, toDay)),
  dispatchGetCashByUser: (fromDay, toDay) => dispatch(getCashByUser(fromDay, toDay)),
  dispatchGetPrivateInvoice: (fromDay, toDay) => dispatch(getPrivateInvoice(fromDay, toDay)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reports));
