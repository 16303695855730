import React from "react";
import clsx from 'clsx';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import NotificationsIcon from '@material-ui/icons/Notifications';
import EmailIcon from '@material-ui/icons/Email';
import InvoiceIcon from '@material-ui/icons/Receipt';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import DoneIcon from '@material-ui/icons/Done';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import localization from 'moment/locale/nl';
import localizationEN from 'moment/locale/en-gb';
import './GroupedTableRow.css';
import SelectField from './SelectField';

class GroupedTableRow extends React.Component {
  constructor(){
    super();
    this.state = {
      hovered: false,
    }
    this.showMenu = this.showMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
    this.sendTheSMS = this.sendTheSMS.bind(this);
    this.sendTheEmail = this.sendTheEmail.bind(this);
    this.createInvoice = this.createInvoice.bind(this);
  }

  showMenu() {
    this.setState({
      hovered: true
    })
  }

  hideMenu() {
    this.setState({
      hovered: false
    })
  }

  sendTheSMS(item) {
    const { sendSMS } = this.props;
    if(item.tel && item.tel.length > 0) {
      const name = item.name;
      const pickupd = new Date(item.pickupd).toLocaleString("nl-BE");
      const pickupa = item.pickupa;
      const dropa = item.dropa;
      const message = `PDR - Automated message: Your driver will pick you up on ` + pickupd + ` at ` + pickupa + ` and bring you to ` + dropa + `. Urgent need? Call +32477497879.`
      sendSMS(item, item.tel, message);
    }
  }

  createInvoice(item) {
    const { createInvoice } = this.props;
    createInvoice(item);
  }

  sendTheEmail(item) {
    const { sendEmail, rides } = this.props;
    if(item.email && item.email.length > 0) {
      const ride = rides.find(r => r.id === item.ride);

      let totalPrice = 0;
      let tracksHtml = "";
      let dates = "";
      let firstRide = "";
      let bf = "b";
      const tracks = ride.tracks.sort((a, b) => moment(a.pickupd) - moment(b.pickupd))
      for(let i = 0; i < tracks.length; i++) {
        const t = tracks[i];
        bf = t.bf;
        totalPrice += parseInt(t.price);
        dates += `${moment(t.pickupd).format("DD/MM")}`
        if(i === tracks.length-2) {
          dates += " en "
        } else if(i < tracks.length-2) {
          dates += ", "
        }
        if(i === 0) {
          firstRide = `${moment(t.pickupd).locale('nl', localization).format("dddd D MMMM")} - ${moment(t.pickupd).format("HH:mm")} in  ${t.pickupa}`
        }
        tracksHtml += `<div>
        <b>${moment(t.pickupd).locale('nl', localization).format("dddd D MMMM YYYY")}</b>
        <table><tr>
        <td>${moment(t.pickupd).locale('nl', localization).format("HH:mm")}</td>
        <td>${t.ht}</td>
        <td>${t.name}</td>
        <td>${t.pickupa}</td>
        <td>${t.dropa}</td>
        <td>${t.numberp}</td>
        <td>${t.price}</td>
        <td>${t.bf === "b" ? "bon" : "factuur"}</td>
        <td>${t.details}</td>
        </tr></td></table></div><br/><br/>`
      }

      const message = `Beste,<br/><br/>

      Hierbij onze bevestiging van uw reservatie voor luchthaven of persoonlijk vervoer op ${dates}.<br/>
      Afspraak op ${firstRide}. <br/>
      De dag voor elke afspraak om 18:00 stipt sturen wij u een sms met de naam en gsm van uw chauffeur.<br/><br/>

      ${tracksHtml}

      ${bf === "b" ? "De afgesproken prijs van €" + totalPrice + " kan u cash of met de kaart betalen aan de chauffeur." : ""}
      ${bf === "f" ? "De factuur van €" + totalPrice + " wordt u aangeboden na geleverde dienst." : ""}
      Gelieve de gegevens van deze mail te controleren en te bevestigen voor akkoord en goede ontvangst.<br/><br/>

      Bedankt voor uw reservatie,<br/><br/>

      Met vriendelijke groeten,<br/><br/>
      
      <table style="margin-top: 30px">
        <tr>
          <td style="padding-right: 30px;">
            <img height="56" width="200" src="https://d36urhup7zbd7q.cloudfront.net/u/QM8p9aEPRPB/c6303ba0-afbd-4b9b-9c7f-5adacf308b6c.png"/>
          </td>
          <td>
            <table style="border-collapse: collapse; ">
              <tr style="border-bottom: 1px solid black; ">
                <td ><strong style="margin-right:20px;">Personen- & luchthavenvervoer</strong></td>
                <td>
                  <a href="ttps://www.linkedin.com/in/philippe-de-rycke-a28750b"><img width="23px" height="23px" src="https://cdn.gifo.wisestamp.com/s/ld/B49042/46/circle/background.png"/></a>
                  <a href="https://www.instagram.com/pdrdriverservices"><img width="23px" height="23px" src="https://cdn.gifo.wisestamp.com/s/inst/B49042/46/circle/background.png"/></a>
                  <a href="https://www.facebook.com/PdrDriverServices"><img width="23px" height="23px" src="https://cdn.gifo.wisestamp.com/s/fb/B49042/46/circle/background.png"/></a>
                </td>
              </tr>
            </table>
            <br/>
            <table style="border-collapse: collapse; ">
              <tr><td style="font-family:Verdana;font-size:11px; line-height: 25px;"><span style="color:#b49042;font-family:Verdana;font-size:11px;font-weight:bold">Gsm</span> +32 477 497 879</td></tr>
              <tr><td style="font-family:Verdana;font-size:11px; line-height: 25px;"><span style="color:#b49042;font-family:Verdana;font-size:11px;font-weight:bold">Email</span> info@pdrdriverservices.be</td></tr>
              <tr><td style="font-family:Verdana;font-size:11px; line-height: 25px;"><span style="color:#b49042;font-family:Verdana;font-size:11px;font-weight:bold">Web</span> pdrdriverservices.be</td></tr>

              <tr style="border-bottom: 1px solid black; "><td></td></tr>
            </table>
          </td>
        </tr>
      </table>
      `;

      dates = "";
      tracksHtml = "";
      totalPrice = 0;
      for(let i = 0; i < tracks.length; i++) {
        const t = tracks[i];
        bf = t.bf;
        totalPrice += parseInt(t.price);
        dates += `${moment(t.pickupd).format("DD/MM")}`
        if(i === tracks.length-2) {
          dates += " and "
        } else if(i < tracks.length-2) {
          dates += ", "
        }
        if(i === 0) {
          firstRide = `${moment(t.pickupd).locale('en', localizationEN).format("dddd D MMMM")} - ${moment(t.pickupd).format("HH:mm")} at  ${t.pickupa}`
        }
        tracksHtml += `<div>
        <b>${moment(t.pickupd).locale('en', localizationEN).format("dddd D MMMM YYYY")}</b>
        <table><tr>
        <td>${moment(t.pickupd).format("HH:mm")}</td>
        <td>${t.ht}</td>
        <td>${t.name}</td>
        <td>${t.pickupa}</td>
        <td>${t.dropa}</td>
        <td>${t.numberp}</td>
        <td>${t.price}</td>
        <td>${t.bf === "b" ? "cash" : "invoice"}</td>
        <td>${t.details}</td>
        </tr></td></table></div><br/><br/>`
      }

      const messageEN = `Dear,<br/><br/>

      Please find below the confirmation for your airport or taxi transfer: ${dates}.<br/>
      We will meet on ${firstRide}. <br/>
      The day before each appointment you will receive a message at 18PM with the name and phone number of your driver.<br/><br/>

      ${tracksHtml}

      ${bf === "b" ? "The agreed price of €" + totalPrice + " can be paid cash or with creditcard to your driver." : ""}
      ${bf === "f" ? "The invoice of €" + totalPrice + " will be delivered to you after the service has been delivered." : ""}

      Please verify the details of this email and confirm for agreement and good reception.<br/><br/>
      Thank you for your reservation,<br/><br/>

      Kind regards,<br/><br/>
      <table style="margin-top: 30px">
          <tr>
            <td style="padding-right: 30px;">
              <img height="56" width="200" src="https://d36urhup7zbd7q.cloudfront.net/u/QM8p9aEPRPB/c6303ba0-afbd-4b9b-9c7f-5adacf308b6c.png"/>
            </td>
            <td>
              <table style="border-collapse: collapse; ">
                <tr style="border-bottom: 1px solid black; ">
                  <td ><strong style="margin-right:20px;">Personen- & luchthavenvervoer</strong></td>
                  <td>
                    <a href="ttps://www.linkedin.com/in/philippe-de-rycke-a28750b"><img width="23px" height="23px" src="https://cdn.gifo.wisestamp.com/s/ld/B49042/46/circle/background.png"/></a>
                    <a href="https://www.instagram.com/pdrdriverservices"><img width="23px" height="23px" src="https://cdn.gifo.wisestamp.com/s/inst/B49042/46/circle/background.png"/></a>
                    <a href="https://www.facebook.com/PdrDriverServices"><img width="23px" height="23px" src="https://cdn.gifo.wisestamp.com/s/fb/B49042/46/circle/background.png"/></a>
                  </td>
                </tr>
              </table>
              <br/>
              <table style="border-collapse: collapse; ">
                <tr><td style="font-family:Verdana;font-size:11px; line-height: 25px;"><span style="color:#b49042;font-family:Verdana;font-size:11px;font-weight:bold">Gsm</span> +32 477 497 879</td></tr>
                <tr><td style="font-family:Verdana;font-size:11px; line-height: 25px;"><span style="color:#b49042;font-family:Verdana;font-size:11px;font-weight:bold">Email</span> info@pdrdriverservices.be</td></tr>
                <tr><td style="font-family:Verdana;font-size:11px; line-height: 25px;"><span style="color:#b49042;font-family:Verdana;font-size:11px;font-weight:bold">Web</span> pdrdriverservices.be</td></tr>

                <tr style="border-bottom: 1px solid black; "><td></td></tr>
              </table>
            </td>
          </tr>
        </table>
      `;
      sendEmail(item, item.email, message, messageEN);
    }
  }

  render() {

    const { item, role, onEdit, onDelete, columnData, drivers, rides } = this.props
    const { hovered } = this.state;

    let ride = null;
    if(item) {
      ride = rides.find(r => r.id === item.ride);
    }

    return<TableRow key={item.id} padding="dense">
      <TableCell padding="checkbox" className={clsx(hovered && "hovered", !hovered && "default" )}>
        { !hovered && <Tooltip title="Open menu" placement="top">
            <ChevronRightIcon className="icon" onClick={this.showMenu}/>
          </Tooltip>
        }

        { hovered && <span>
            <Tooltip title="Close menu" placement="top">
              <ChevronLeftIcon onClick={this.hideMenu} className="icon"/>
            </Tooltip>
            <Tooltip title="Edit ride" placement="top">
              <EditIcon onClick={() => onEdit(item)} className="icon"/>
            </Tooltip>
            { role && role === 'admin' &&
              <Tooltip title="Delete ride" placement="top">
                <DeleteIcon onClick={() => onDelete(item)}className="icon"/>
              </Tooltip>
            }
            { role && role === 'admin' &&
              <Tooltip title="Send notification to customer" placement="top">
                <NotificationsIcon color={item.smsSend ? "primary": "inherit"} onClick={() => this.sendTheSMS(item)} className="icon"/>
              </Tooltip>
            }
            { role && role === 'admin' &&
              <Tooltip title="Send confirmation email to customer" placement="top">
                <EmailIcon color={ride.emailSend ? "primary": "inherit"} onClick={() => this.sendTheEmail(item)} className="icon"/>
              </Tooltip>
            }
          </span>
        }
      </TableCell>

      {columnData.map(col => {
        const key = col.dataKey;
        const value = item[col.dataKey];
        if(key === 'pickupd') {
          const time = moment(value).format("HH:mm")
          return <TableCell key={col.dataKey}>{time}</TableCell>
        }
        else if(key === 'name' || key === 'details') {
          return <TableCell style={{ minWidth: "200px" }} key={col.dataKey}>
              {value}
            </TableCell>
        }
        else if(key === 'pickupt') {
          // not used anymore.
          return null
        }
        else if(key === 'htdate') {
          if(item.isHT === true) {
            const date = moment(value).format("DD/MM");
            return <TableCell key={col.dataKey}>{date}</TableCell>
          }
          return <TableCell key={col.dataKey}></TableCell>
        }
        else if(key === 'driver' && role === 'admin'){
          const driver = drivers.find(d => d.id === value)
          return <SelectField
            canEdit={role === 'admin'}
            key={col.dataKey}
            item={item}
            itemId={item.id}
            update={this.props.updateDriver}
            dataKey={col.dataKey}
            data={driver}
            list={drivers}/>
        } else if(key === 'driver' && role !== 'admin'){
          const driver = drivers.find(d => d.id === value)
          if (driver) {
            return <TableCell 
                        key={col.dataKey} 
                        style={{backgroundColor: driver.color}}>{driver.username}</TableCell>
          }
          return <TableCell key={col.dataKey}>-</TableCell>
        } else if(key === 'numberp'){
          return <TableCell
            key={col.dataKey}
            className={clsx("centered", value >= 4 && "highlight")}>
              {value}
            </TableCell>
        } else if(key === 'validated'){
          return <TableCell
              key={col.dataKey}>
              { value && <DoneIcon/> }
            </TableCell>
        } else if(key === 'paid' && role !== 'planner'){
          return <TableCell
              key={col.dataKey}>
              { value && <DoneIcon/> }
            </TableCell>
        } else if(key === 'bf'){
          return <TableCell key={col.dataKey} className={clsx({"invoice-created": item["invoiceCreated"]})}>
              { value === 'f' && <span style={{color: "#ff6464"}}>Fact</span> }
              { value === 'b' && 'Bon' }
              { value === 'n' && 'No' }
            </TableCell>
        } else if(key === 'price' && role !== 'planner'){
          return <TableCell key={col.dataKey} className={clsx(item["paid"] && "paid")}>
              €{ value }
            </TableCell>
        }  else if(key === 'dropa' || key === 'pickupa'){

          // Split address into street and city.
          const address = value;
          let street = address;
          let city = "";
          const addressSplitted = address.split(",");
          if(addressSplitted.length > 1) {
            street = addressSplitted[0];
            city = addressSplitted[1];
          }

          let longride = false;
          if (item["traveltime"] >= 180 ) {
            if(item["ht"] === 'H' && key === 'dropa') {
              longride = true
            }
            if(item["ht"] === 'T' && key === 'pickupa') {
              longride = true
            }
          }
          return <TableCell style={{ minWidth: "330px" }} key={col.dataKey} className={clsx(longride && "long-ride")}>
              {street}
              {city.length > 0 && <span>
                <br/>
                {city}
              </span>}
            </TableCell>
        } else {
          return <TableCell key={col.dataKey}>{value}</TableCell>
        }
      })}
    </TableRow>
  }
}

export default GroupedTableRow;
