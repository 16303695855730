import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { login } from '../../actions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import './LoginForm.css';
import config from '../../config';

class LoginForm extends React.Component {

  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    if(config.IS_DEMO) {
      this.props.dispatchLogin("demo", "demo");
    } else {
      this.props.dispatchLogin(data.get('username'), data.get('password'));
    }
  }

  render() {

    const { loginError } = this.props;

    return <div>
      <form onSubmit={this.handleSubmit}>

        { loginError && <span className="error">Something went wrong. Make sure you used the right credentials.</span> }

        { config.IS_DEMO &&
            <>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                value="demo"
                disabled="true"
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value="demo"
                disabled="true"
              />
           </> }


           { !config.IS_DEMO &&
               <>
                 <TextField
                   variant="outlined"
                   margin="normal"
                   required
                   fullWidth
                   id="username"
                   label="Username"
                   name="username"
                 />
                 <TextField
                   variant="outlined"
                   margin="normal"
                   required
                   fullWidth
                   name="password"
                   label="Password"
                   type="password"
                   id="password"
                 />
              </> }


        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className="button"
        >
          Sign In
        </Button>
      </form>
    </div>
  }
}

const mapStateToProps = (state, ownProps) => ({
  loginError: state.auth.loginError,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchLogin: (username, password) => {
    dispatch(login(username, password))
  },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm));
