import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import './RegisterChiron.css';
import DoneIcon from '@material-ui/icons/Done';
import moment from 'moment';
import Geocode from "react-geocode";

class RegisterChiron extends React.Component {

  constructor() {
    super();
    Geocode.setApiKey("AIzaSyAuSAJokJU3rdPyaA0ktsIXm-ZNJzRVM4A");

    this.onSubmit = this.onSubmit.bind(this);
    this.changeCar = this.changeCar.bind(this);

    this.state = {
      amountReceived: "",
      bookNumber: "",
      paidAll: false,
      car: "",
    };
  }

  componentDidUpdate(prevProps){
    const { currentTrack, currentRide } = this.props;

    if(currentTrack && prevProps.currentTrack !== currentTrack) {

      // Get geocodes from pickup address
      Geocode.fromAddress(currentTrack.pickupa).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location;
          this.setState({
            pickupcoordinates: {
              lat,
              lng,
            }
          })
        },
        error => {
          console.error(error);
        }
      );

      // Get geocodes from drop address
      Geocode.fromAddress(currentTrack.dropa).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location;
          this.setState({
            dropcoordinates: {
              lat,
              lng,
            }
          })
        },
        error => {
          console.error(error);
        }
      );

      let driver = null;
      if(this.props.drivers) {
        const d = this.props.drivers.find(d => d.id === currentTrack.driver);
        if(d) {
          driver = {
            name: d.username,
            driverNumber: d.driverNumber
          };
        }
      }

      this.setState({
        ...currentTrack,
        driverNumber: driver && driver.driverNumber,
      });
    }
    if(currentRide && prevProps.currentRide !== currentRide) {
      this.setState({
        paidAll: currentRide.paidAll
      })
    }
  }

  onSubmit() {
    const { currentTrack, currentRide } = this.props;
    this.props.onSubmit(currentTrack.id, currentTrack, {
      ...this.state,
      chironDirection: this.props.direction,
    });
    this.props.handleClose();
  }

  changeCar (e){
    this.setState({ car: e.target.value });
  }

  render() {

    return (
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          className="MarkAsPaid"
          maxWidth="md">
          <DialogTitle id="alert-dialog-title">{ this.props.title } > { this.props.direction }</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>

              <Grid item xs={12} md={12}>
                  <FormControl fullWidth variant="filled">
                    <InputLabel>Select the car you are driving</InputLabel>
                    <Select
                      value={this.state.car}
                      onChange={this.changeCar}
                      margin="dense"
                    >
                      { this.props.cars && this.props.cars.map(c => {
                        return <MenuItem value={c.license_plate}>{c.name}</MenuItem>
                      })}
                    </Select>
                  </FormControl>

                  <TextField
                    id="direction"
                    label="Direction"
                    margin="dense"
                    type="text"
                    fullWidth
                    value={ this.props.direction}
                    disabled />

                  <TextField
                    id="ride"
                    label="Ride Number"
                    margin="dense"
                    type="text"
                    fullWidth
                    value={ this.state.ride }
                    disabled />

                  <TextField
                    id="ride"
                    label="Price"
                    margin="dense"
                    type="text"
                    fullWidth
                    value={ this.state.price }
                    disabled />

                  <TextField
                    id="driver_number"
                    label="Driver Number"
                    margin="dense"
                    type="text"
                    className={ this.state.driverNumber ? "success" : "notok"}
                    fullWidth
                    value={ this.state.driverNumber ? this.state.driverNumber : "" }
                    disabled />

                  <TextField
                    id="pickup_address"
                    label="Pickup Addess"
                    margin="dense"
                    type="text"
                    fullWidth
                    value={ `${this.state.pickupa} (${this.state.pickupcoordinates && this.state.pickupcoordinates.lng}, ${this.state.pickupcoordinates && this.state.pickupcoordinates.lat})` }
                    disabled />

                  <TextField
                    id="drop_address"
                    label="Drop Addess"
                    margin="dense"
                    type="text"
                    fullWidth
                    value={ `${this.state.dropa} (${this.state.dropcoordinates && this.state.dropcoordinates.lng}, ${this.state.dropcoordinates && this.state.dropcoordinates.lat})` }
                    disabled />

              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleClose}>
              Cancel
            </Button>
            <Button onClick={this.onSubmit} color="primary" disabled={!(this.state.driverNumber) || !this.state.car}>
              Send to chiron
            </Button>
          </DialogActions>
        </Dialog>
    );
  }
}

export default RegisterChiron;
