import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import ColorPicker from 'material-ui-color-picker'

import './AddEditUser.css';

class AddEditUser extends React.Component {
  constructor(){
    super();
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.changeUsername = this.changeUsername.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.changePhone = this.changePhone.bind(this);
    this.changeRole = this.changeRole.bind(this);
    this.changeColor = this.changeColor.bind(this);
    this.changeInternal = this.changeInternal.bind(this);
    this.changeActive = this.changeActive.bind(this);
    this.changeDriverNumber = this.changeDriverNumber.bind(this);

    // if edit, we can initialise the state
    // and assign the id.

    this.id = -1;
    this.initialState = {
      username: "",
      email: "",
      password: "",
      color: "",
      phone: "",
      role: "",
      error: false,
      internal: false,
      active: false,
      driverNumber: "",
    };

    this.state = this.initialState;
  }

  // Check if we selected a user or not.
  componentDidUpdate(prevProps) {
    const { currentUser } = this.props;
    if(prevProps.currentUser !== currentUser) {
      if(currentUser) {
        this.id = currentUser.id
        this.setState({
          ...currentUser,
          error: false
        })
      } else {
        this.id = -1;
        this.setState(this.initialState);
      }
    }
  }

  handleClose() {
    this.props.onClose();
  }

  validateForm() {
    const { username, email, password, color, role, driverNumber} = this.state;
    if(!this.checkIfValid(username)) {
      return false;
    }
    if(!this.checkIfValid(email)) {
      return false;
    }
    if(!this.checkIfValid(password)) {
      return false;
    }
    if(!this.checkIfValid(color)) {
      return false;
    }
    if(!this.checkIfValid(role)) {
      return false;
    }
    if(!this.checkIfValid(driverNumber)) {
      return false;
    }
    return true;
  }

  handleSubmit(event) {
    event.preventDefault();
    if(this.validateForm()){
      // Send data..
      this.props.saveUser(this.id, { ...this.state })
      this.handleClose();
      this.setState({error: false});
    }
    else {
      this.setState({error: true});
    }
  }

  checkIfValid(value) {
    return value && value.length > 0;
  }

  changeUsername(e) {
    this.setState({ username: e.target.value });
  }

  changeEmail (e){
    this.setState({ email: e.target.value });
  }

  changePassword (e){
    this.setState({ password: e.target.value });
  }

  changePhone (e){
    this.setState({ phone: e.target.value });
  }

  changeRole (e){
    this.setState({ role: e.target.value });
  }

  changeDriverNumber (e){
    this.setState({ driverNumber: e.target.value });
  }

  changeInternal (e){
    this.setState({ internal: e.target.checked });
  }

  changeActive (e){
    this.setState({ active: e.target.checked });
  }

  changeColor (color){
    this.setState({ color });
  }

  render() {

      const { error } = this.state;

      return <div>
        <Dialog open={this.props.open} onClose={this.handleClose} maxWidth="sm" aria-labelledby="form-dialog-title">

          <DialogTitle id="form-dialog-title">{ this.id !== -1 ? 'Edit' : 'Add a new' } user</DialogTitle>
          <form onSubmit={this.handleSubmit}>
            <DialogContent>
                { error && <DialogContentText className="error">Please fill-in all fields correctly.</DialogContentText> }
                <Grid container={true} spacing={0}>
                  <Grid item xs={12}>

                    <TextField
                      margin="dense"
                      id="username"
                      label="Username"
                      type="text"
                      fullWidth
                      value={ this.state.username }
                      onChange={ this.changeUsername }/>

                    <ColorPicker
                      className="fullWidth"
                      name='color'
                      defaultValue='#000'
                      value={ this.state.color }
                      onChange={  this.changeColor } />

                    <TextField
                      margin="dense"
                      id="email"
                      label="E-mail"
                      type="text"
                      fullWidth
                      value={ this.state.email }
                      onChange={ this.changeEmail }/>

                    <TextField
                      margin="dense"
                      id="phone"
                      label="Phone"
                      type="text"
                      fullWidth
                      value={ this.state.phone }
                      onChange={ this.changePhone }/>


                    <TextField
                      margin="dense"
                      id="password"
                      label="Password"
                      type="text"
                      fullWidth
                      value={ this.state.password }
                      onChange={ this.changePassword }/>

                    <TextField
                      margin="dense"
                      id="driverNumber"
                      label="Driver Number"
                      type="text"
                      fullWidth
                      value={ this.state.driverNumber }
                      onChange={ this.changeDriverNumber }/>

                    <FormControl fullWidth variant="filled">
                      <InputLabel>Role</InputLabel>
                      <Select onChange={this.changeRole}
                        required
                        value={this.state.role}
                        margin="dense"
                        label="Role"
                        inputProps={{
                          name: 'role',
                          id: 'role-simple',
                        }}
                      >
                        <MenuItem value="admin">Admin</MenuItem>
                        <MenuItem value="driver">Driver</MenuItem>
                        <MenuItem value="planner">Planner</MenuItem>
                      </Select>
                    </FormControl>


                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.active}
                          onChange={this.changeActive}
                          value="active"
                          color="primary"
                        />
                      }
                      label="Active (Y/N)"
                    />


                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.internal}
                          onChange={this.changeInternal}
                          value="internal"
                          color="primary"
                        />
                      }
                      label="Internal (Y/N)"
                    />

                  </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.handleClose()} color="primary">
                Cancel
              </Button>
              <Button color="primary" type="submit">
                { this.id !== -1 ? "Edit" : "Add" } User
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>;
  }
}

export default AddEditUser;
