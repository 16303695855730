import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import PaymentIcon from '@material-ui/icons/Payment';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import LastPageIcon from '@material-ui/icons/LastPage';
import NextPageIcon from '@material-ui/icons/NavigateNext';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import RefreshIcon from '@material-ui/icons/Refresh';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logout, getRidesByDay, getFilteredRides, getCars, getUsers, sendChiron, addEditRide, removeContract, getContractFromRide } from '../actions';
import moment from 'moment';
import config from '../config';
import RideContract from '../containers/RideContract/RideContract';
import AddEditRide from '../components/AddEditRide/AddEditRide';
import MarkAsPaid from '../components/MarkAsPaid/MarkAsPaid';
import DigitalBoard from '../components/DigitalBoard/DigitalBoard';
import RegisterChiron from '../components/RegisterChiron/RegisterChiron';
import './Home.css';

class Home extends React.Component {

  constructor() {
    super();
    this.save = this.save.bind(this);
    this.sendChiron = this.sendChiron.bind(this);
    this.signout = this.signout.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.openContract = this.openContract.bind(this);
    this.closeContract = this.closeContract.bind(this);
    this.openPaid = this.openPaid.bind(this);
    this.closePaid = this.closePaid.bind(this);
    this.openBoard = this.openBoard.bind(this);
    this.closeBoard = this.closeBoard.bind(this);
    this.openRegisterChiron = this.openRegisterChiron.bind(this);
    this.closeRegisterChiron = this.closeRegisterChiron.bind(this);
    this.handleClickEditOpen = this.handleClickEditOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getRides = this.getRides.bind(this);
    this.removeContract = this.removeContract.bind(this);
    this.today = config.IS_DEMO ? this.formatDate(moment('2019-10-12').startOf('day')) : this.formatDate(moment().startOf('day'));
    this.tomorrow = config.IS_DEMO ? this.formatDate(moment('2019-10-12').startOf('day').add(1, 'days')) : this.formatDate(moment().startOf('day').add(1, 'days'));
    this.state = {
      ride: null,
      open: false,
      openContract: false,
      openPaid: false,
      openBoard: false,
      openRegisterChiron: false,
      contract: null,
      chironDirection: null,
    };
  }

  componentDidMount() {
    this.getRides();
    this.props.dispatchGetUsers();
    this.props.dispatchGetCars();
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  getRides() {
    const day = config.IS_DEMO ? moment('2019-10-12').startOf('day') : moment().startOf('day');
    this.props.dispatchGetRidesByDay(this.formatDate(day), this.formatDate(day.add(1, 'days')));
    this.props.dispatchGetRidesByDay(this.formatDate(day), this.formatDate(day.add(1, 'days')));
    this.props.dispatchGetFilteredRides();
  }

  formatDate(date) {
    return date.unix();
  }

  signout() {
    this.props.dispatchLogout();
  }

  myRides(day, ridesByDay, drivers) {
    const { auth } = this.props;
    const driver = drivers.filter(d => d.username === auth.username);
    return this.allRides(day, ridesByDay, driver);
  }

  onEdit(ride) {}

  openContract(ride){
    this.props.dispatchGetContractFromRide(ride.ride, (contract) => {
      this.setState({
        ride,
        openContract: true,
        contract
      });
    });
  }

  closeContract(){
    this.setState({
      openContract: false,
      contract: null
    });
  }

  openPaid(track){
    this.setState({
      currentRide: this.props.rides.find(r => r.id === track.ride),
      currentTrack: track,
      openPaid: true,
    });
  }

  closePaid(){
    this.setState({
      openPaid: false,
    });
  }

  openRegisterChiron(track, direction){
    this.setState({
      currentRide: this.props.rides.find(r => r.id === track.ride),
      currentTrack: track,
      openRegisterChiron: true,
      chironDirection: direction,
    });
  }

  closeRegisterChiron(){
    this.setState({
      openRegisterChiron: false,
    });
  }

  openBoard(track){
    this.setState({
      currentRide: this.props.rides.find(r => r.id === track.ride),
      currentTrack: track,
      openBoard: true,
    });
  }

  closeBoard(){
    this.setState({
      openBoard: false,
    });
  }

  save(id, ride, data) {
    this.props.dispatchAddEditRide(id, ride, data, (d) => {
      let currentRide = null;
      if(ride) {
        currentRide = this.props.rides.find(r => r.id === ride.id);
      } else { // newly added :)
        currentRide = d;
      }
      this.setState({
        currentRide,
        currentTrack: null
      });
      this.getRides();
    });
  }

  sendChiron(id, track, data) {
    this.props.dispatchSendChiron(id, track, data);
  }

  removeContract(id) {
    this.props.dispatchRemoveContract(id, this.getRides);
  }

  handleClickEditOpen(track) {
    this.setState({
      open: true,
      currentRide: this.props.rides.find(r => r.id === track.ride),
      currentTrack: track
    });
  }

  handleClickEditOpenFilter(track) {
    this.setState({
      open: true,
      currentRide: this.props.filteredRides.find(r => r.id === track.ride),
      currentTrack: track
    });
  }

  handleClose() {
    this.setState({
      open: false
    });
  }

  filteredRides(rides, drivers, validated = true) {
    if(rides && drivers) {
      let tracks = rides.reduce((tracks, ride) => {
        for(let i = 0; i < ride.tracks.length; i++) {
          let t = ride.tracks[i];
          t.ride = ride.id;
          t.pickupd = moment(t.pickupd);
          t.htdate = moment(t.htdate);

          // we are grouping tracks in rides.
          // Therefore we need an additional filter.
          const currentDay = moment();
          if(t.pickupd >= currentDay){

            if(validated && t.validated) {
              tracks.push({
                track: t,
                ride: ride
              });
            } else if(!validated && !t.validated) {
              tracks.push({
                track: t,
                ride: ride
              });
            }
          }
        }
        return tracks
      }, [])

      tracks = tracks.sort((a, b) => a.track.pickupd - b.track.pickupd);
      tracks = tracks.map(r => {
        const driver = drivers.find(d => d.id === r.track.driver);
        const pickupt = moment(r.track.pickupd).format("DD/MM HH:mm");

        // Split address into street and city.
        const pickupa = r.track.pickupa;
        let pickupstreet = pickupa;
        let pickupcity = "";
        const pickupaSplitted = pickupa.split(",");
        if(pickupaSplitted.length > 1) {
          pickupstreet = pickupaSplitted[0];
          pickupcity = pickupaSplitted[1];
        }

        const dropa = r.track.dropa;
        let dropstreet = dropa;
        let dropcity = "";
        const dropaSplitted = dropa.split(",");
        if(dropaSplitted.length > 1) {
          dropstreet = dropaSplitted[0];
          dropcity = dropaSplitted[1];
        }

        let longrideH = false;
        let longrideT = false;
        if (r.track.traveltime >= 180 ) {
          if(r.track.ht === 'H') {
            longrideT = true
          }
          if(r.track.ht === 'T') {
            longrideH = true
          }
        }


        return <TableRow key={r.track.id}>
          <TableCell padding="checkbox" style={{minWidth: "100px"}}>
            <Tooltip title="Edit ride" placement="top">
              <EditIcon onClick={() => this.handleClickEditOpenFilter(r.track)} className="icon"/>
            </Tooltip>
          </TableCell>
          { /*<TableCell padding="checkbox">
          <Tooltip title="View ride" placement="top">
            <FindInPageIcon onClick={() => this.handleClickEditOpen(ride)} className="icon"/>
          </Tooltip>
          </TableCell> */ }

          { driver && <TableCell align="center" style={{backgroundColor: driver.color}}>{driver.username}</TableCell> }
          { !driver && <TableCell align="center">-</TableCell>}

          { /* <TableCell align="right">{ ride.name }</TableCell>
          <TableCell align="right">{ ride.tel }</TableCell>  */ }
          <TableCell align="center">{ r.track.ht }</TableCell>
          <TableCell align="center">{ pickupt }</TableCell>
          <TableCell align="center">{ r.track.name }</TableCell>
          <TableCell align="left" className={clsx(longrideH && "long-ride")}>
            {pickupstreet}
            {pickupcity.length > 0 && <span>
              <br/>
              {pickupcity}
            </span>}
          </TableCell>
          <TableCell align="left" className={clsx(longrideT && "long-ride")}>
            {dropstreet}
            {dropcity.length > 0 && <span>
              <br/>
              {dropcity}
            </span>}
          </TableCell>
          <TableCell align="center" className={clsx(r.track.numberp >= 4 && "highlight")}>
            {r.track.numberp}
          </TableCell>
          <TableCell align="center">{r.track.bf.toUpperCase()}</TableCell>
          <TableCell align="center">{r.track.details}</TableCell>

          { /* <TableCell align="right">{ride.dropa}</TableCell>
          <TableCell align="right">EUR{ ride.price }</TableCell>
          <TableCell align="right">{ ride.bf }</TableCell>   */ }
        </TableRow>
      });

      return tracks;
    }
  }

  allRides(day, ridesByDay, drivers) {
    if(ridesByDay && drivers && ridesByDay[day]) {
      let tracks = ridesByDay[day].reduce((tracks, ride) => {
        for(let i = 0; i < ride.tracks.length; i++) {
          let t = ride.tracks[i];
          t.ride = ride.id;
          t.pickupd = moment(t.pickupd);
          t.htdate = moment(t.htdate);

          // we are grouping tracks in rides.
          // Therefore we need an additional filter.
          const currentDay = moment.unix(day);
          const dayAfter = moment.unix(day).add(1, 'days')

          if(t.pickupd >= currentDay && t.pickupd < dayAfter){
            tracks.push({
              track: t,
              ride: ride
            });
          }
        }
        return tracks
      }, [])

      tracks = tracks.sort((a, b) => a.track.pickupd - b.track.pickupd);
      tracks = tracks.map(r => {
        const driver = drivers.find(d => d.id === r.track.driver);
        const pickupt = moment(r.track.pickupd).format("HH:mm");

        // Split address into street and city.
        const pickupa = r.track.pickupa;
        let pickupstreet = pickupa;
        let pickupcity = "";
        const pickupaSplitted = pickupa.split(",");
        if(pickupaSplitted.length > 1) {
          pickupstreet = pickupaSplitted[0];
          pickupcity = pickupaSplitted[1];
        }

        const dropa = r.track.dropa;
        let dropstreet = dropa;
        let dropcity = "";
        const dropaSplitted = dropa.split(",");
        if(dropaSplitted.length > 1) {
          dropstreet = dropaSplitted[0];
          dropcity = dropaSplitted[1];
        }

        let longrideH = false;
        let longrideT = false;
        if (r.track.traveltime >= 180 ) {
          if(r.track.ht === 'H') {
            longrideT = true
          }
          if(r.track.ht === 'T') {
            longrideH = true
          }
        }

        return driver && <TableRow key={r.track.id}>
          <TableCell padding="checkbox" style={{minWidth: "100px"}}>
            <Tooltip title="Edit ride" placement="top">
              <EditIcon onClick={() => this.handleClickEditOpen(r.track)} className="icon"/>
            </Tooltip>
            <Tooltip title="Show Contract" placement="top">
              <PhotoAlbumIcon color={r.ride.contract === "true" ? "primary": "inherit"} onClick={() => this.openContract(r.track)} className="icon"/>
            </Tooltip>
            <Tooltip title="Mark ride as paid" placement="top">
              <PaymentIcon color={r.track.paid ? "primary": "inherit"} onClick={() => this.openPaid(r.track)} className="icon"/>
            </Tooltip>
            <Tooltip title="Board" placement="top">
              <AspectRatioIcon onClick={() => this.openBoard(r.track)}  className="icon"/>
            </Tooltip>
            <Tooltip title="Register Chiron Departure" placement="top">
              <NextPageIcon color={r.track.chironDeparture === true ? "primary": "inherit"} onClick={() => this.openRegisterChiron(r.track, "departure")} className="icon"/>
            </Tooltip>
            <Tooltip title="Register Chiron Arrival" placement="top">
              <LastPageIcon color={r.track.chironArrival === true ? "primary": "inherit"} onClick={() => this.openRegisterChiron(r.track, "arrival")} className="icon"/>
            </Tooltip>
          </TableCell>
          { /*<TableCell padding="checkbox">
          <Tooltip title="View ride" placement="top">
            <FindInPageIcon onClick={() => this.handleClickEditOpen(ride)} className="icon"/>
          </Tooltip>
          </TableCell> */ }

          <TableCell align="center" style={{backgroundColor: driver.color}}>{driver.username}</TableCell>
          { /* <TableCell align="right">{ ride.name }</TableCell>
          <TableCell align="right">{ ride.tel }</TableCell>  */ }
          <TableCell align="center">{ r.track.ht }</TableCell>
          <TableCell align="center">{ pickupt }</TableCell>
          <TableCell align="center">{ r.track.name }</TableCell>
          <TableCell align="left" className={clsx(longrideH && "long-ride")}>
            {pickupstreet}
            {pickupcity.length > 0 && <span>
              <br/>
              {pickupcity}
            </span>}
          </TableCell>
          <TableCell align="left" className={clsx(longrideT && "long-ride")}>
            {dropstreet}
            {dropcity.length > 0 && <span>
              <br/>
              {dropcity}
            </span>}
          </TableCell>
          <TableCell align="center" className={clsx(r.track.numberp >= 4 && "highlight")}>
            {r.track.numberp}
          </TableCell>
          <TableCell align="center">{r.track.bf.toUpperCase()}</TableCell>
          <TableCell align="center">{r.track.details}</TableCell>

          { /* <TableCell align="right">{ride.dropa}</TableCell>
          <TableCell align="right">EUR{ ride.price }</TableCell>
          <TableCell align="right">{ ride.bf }</TableCell>   */ }
        </TableRow>
      });

      return tracks;
    }
  }

  render(){
    const { ridesByDay, rides, filteredRides, drivers } = this.props;
    const today = this.today;
    const tomorrow = this.tomorrow;
    return <div>

      <RideContract
        rides={rides}
        track={this.state.ride}
        open={this.state.openContract}
        onClose={this.closeContract}
        refresh={this.getRides}
        contract={this.state.contract}/>

      <AddEditRide
        drivers={drivers}
        role={this.props.auth && this.props.auth.role}
        saveRide={this.save}
        currentRide={this.state.currentRide}
        currentTrack={this.state.currentTrack}
        open={this.state.open}
        removeContract={this.removeContract}
        onClose={this.handleClose}/>

      <RegisterChiron
        open={this.state.openRegisterChiron}
        handleClose={this.closeRegisterChiron}
        title="Register Chiron"
        message=""
        direction={this.state.chironDirection}
        currentRide={this.state.currentRide}
        currentTrack={this.state.currentTrack}
        drivers={this.props.drivers}
        cars={this.props.cars}
        onSubmit={this.sendChiron}/>

      <MarkAsPaid
        open={this.state.openPaid}
        handleClose={this.closePaid}
        title="Customer paid"
        message=""
        currentRide={this.state.currentRide}
        currentTrack={this.state.currentTrack}
        drivers={this.props.drivers}
        onSubmit={this.save}/>

      <DigitalBoard
        open={this.state.openBoard}
        handleClose={this.closeBoard}
        title=""
        message=""
        currentRide={this.state.currentRide}
        currentTrack={this.state.currentTrack}/>

  

    <Grid className="home" container spacing={3}>
      { this.props.auth.role === 'planner' && <Grid item xs={12} md={12} lg={12}>
              <Paper className="padding">
                <Typography variant="h5" component="h3">
                Approved Rides
                </Typography>
                <Table className="margin">
                  <TableBody>
                    { this.filteredRides(filteredRides, drivers, true) }
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
      }
      { (this.props.auth.role === 'planner' || this.props.auth.role === 'admin' ) && <Grid item xs={12} md={12} lg={12}>
            <Paper className="padding">
              <Typography variant="h5" component="h3">
              Pending Rides
              </Typography>
              <Table className="margin">
                <TableBody>
                  { this.filteredRides(filteredRides, drivers, false) }
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        }
      </Grid>
          
      { this.props.auth.role !== 'planner' && <div><Grid className="home" container spacing={3}>
        <Grid item xs={12} md={12} lg={6}>
          <Paper className="padding">
            <Typography variant="h5" component="h3">
              My rides today
            </Typography>
            <Table className="margin">
              <TableBody>
                { this.myRides(today, ridesByDay, drivers) }
              </TableBody>
            </Table>
          </Paper>
          <br/>
          <Paper className="padding">
            <Typography variant="h5" component="h3">
              All rides today
            </Typography>
            <Table className="margin">
              <TableBody>
                { this.allRides(today, ridesByDay, drivers) }
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Paper className="padding">
            <Typography variant="h5" component="h3">
              My Rides tomorrow
            </Typography>
            <Table className="margin">
              <TableBody>
                { this.myRides(tomorrow, ridesByDay, drivers) }
              </TableBody>
            </Table>
          </Paper>
          <br/>
          <Paper className="padding">
            <Typography variant="h5" component="h3">
              All rides tomorrow
            </Typography>
            <Table className="margin">
              <TableBody>
                { this.allRides(tomorrow, ridesByDay, drivers) }
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid></div> }



      <Tooltip title="Refresh rides" placement="top">
        <Fab color="primary" aria-label="Add" className="add" onClick={this.getRides}>
          <RefreshIcon/>
        </Fab>
      </Tooltip>
    </div>
  }
}

const mapStateToProps = (state, ownProps) => ({
  drivers: state.users.list ? state.users.list.filter(u => u.username !== 'admin') : [],
  cars: state.cars.list,
  ridesByDay: state.rides.listByDay,
  filteredRides: state.rides.filteredList,
  rides: Object.keys(state.rides.listByDay).map(k => {
    return state.rides.listByDay[k]
  }).reduce((arr, val) => {
    if(val){
      for(const v of val) {
        arr.push(v);
      }
    }
    return arr;
  }, []),
  auth: state.auth,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchAddEditRide: (id, ride, data, callback) => dispatch(addEditRide(id, ride, data, callback)),
  dispatchSendChiron: (id, track, data, callback) => dispatch(sendChiron(id, track, data, callback)),
  dispatchRemoveContract: (id, callback) => dispatch(removeContract(id, callback)),
  dispatchLogout: () => dispatch(logout()),
  dispatchGetRidesByDay: (fromDay, toDay) => dispatch(getRidesByDay(fromDay, toDay)),
  dispatchGetUsers: () => dispatch(getUsers()),
  dispatchGetCars: () => dispatch(getCars()),
  dispatchGetContractFromRide: (id, callback) => dispatch(getContractFromRide(id, callback)),
  dispatchGetFilteredRides: () => dispatch(getFilteredRides()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
