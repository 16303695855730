import API from './api';

export function doLogin(username, password, onSuccess, onError) {
  API.post(`login`, {
    username: username,
    password: password
  })
  .then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}


export function doAuth(onSuccess, onError) {

}

export function doRefreshToken(onSuccess, onError) {

}
