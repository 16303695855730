import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import MockAdapter from 'axios-mock-adapter';
import config from '../config';

const hostname = window.location.hostname;
const protocol = window.location.protocol;

const a = axios.create({
  //baseURL:  `${protocol}//api.demo.caboffice.io/`
  //baseURL:  `http://localhost:3001/`
  baseURL:  config.API_URL
});

if(config.IS_DEMO) {
  const mock = new MockAdapter(a, { onNoMatch: "passthrough" });
  mock.onPost(/\/login/).passThrough().onPost().reply(500);
  mock.onPut().reply(500);
  mock.onDelete().reply(500);
}

a.interceptors.request.use(request => {
    request.headers['Authorization'] = "Bearer " + localStorage.getItem('token');
    return request;
});

const refreshAuthLogic = failedRequest => a.get('refresh_token').then(tokenRefreshResponse => {
    localStorage.setItem('token', tokenRefreshResponse.data.token);
    localStorage.setItem('expire', tokenRefreshResponse.data.expire);
    failedRequest.response.config.headers['Authentication'] = 'Bearer ' + tokenRefreshResponse.data.token;
    return Promise.resolve();
});

export default (config.IS_DEMO) ? a : createAuthRefreshInterceptor(a, refreshAuthLogic);
