import API from './api';

export function doGetHoursByUser(fromDay, toDay, onSuccess, onError) {
  API.get(`reports/hoursbyuser/${fromDay}/${toDay}`)
  .then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}

export function doGetCashByUser(fromDay, toDay, onSuccess, onError) {
  API.get(`reports/cashbyuser/${fromDay}/${toDay}`)
  .then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}

export function doGetPrivateInvoices(fromDay, toDay, onSuccess, onError) {
  API.get(`reports/privateinvoice/${fromDay}/${toDay}`)
  .then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}
export function doGetPrivateInvoiceExcel(fromDay, toDay, onSuccess, onError) {
  API.get(`files/Particulieren-${fromDay}-${toDay}.xlsx`, {
    responseType: 'blob',
  })
  .then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    var fileURL = window.URL.createObjectURL(new Blob([data]));
    var fileLink = document.createElement('a');

    fileLink.href = fileURL;
    fileLink.setAttribute('download', 'file.xlsx');
    document.body.appendChild(fileLink);

    fileLink.click();
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}
