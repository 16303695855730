import API from './api';

export function doAddRide(data, onSuccess, onError) {
  API.post(`rides`, {
    ...data
  })
  .then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}

export function doAddTrack(ride, data, onSuccess, onError) {
  API.post(`rides/${ride.id}`, {
    ...data
  })
  .then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}

export function doEditTrack(id, data, onSuccess, onError) {
  API.put(`rides/${id}`, {
    id,
    ...data
  })
  .then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}

export function doSendChiron(id, data, onSuccess, onError) {
  API.post(`rides/${id}/chiron`, {
    ...data
  })
  .then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}

export function doSaveContract(id, image, onSuccess, onError) {
  API.put(`rides/${id}/contract`, {
    id,
    contract: image,
  })
  .then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}

export function doGetContractFromRide(id, onSuccess, onError) {
  API.get(`rides/contract/${id}`, {
    id
  })
  .then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}

export function doUpdateDriver(id, driver, onSuccess, onError) {
  API.put(`rides/${id}/driver`, {
    id,
    driver,
  })
  .then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}

export function doDeleteRide(id, onSuccess, onError) {
  API.delete(`rides/${id}`)
  .then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}

export function doGetRides(timestamp, customer, status, onSuccess, onError) {
  if(timestamp) {
    let endpoint = API.post(`rides/startfrom/${timestamp}`, {customer, status});
    endpoint.then(res => {
      if(res.status !== 200) {
        throw new Error(res.data)
      }
      return res.data;
    }).then(function (data) {
      onSuccess(data);
    }).catch(function (error) {
      onError(error);
    });
  }
}

export function doGetFilteredRides(onSuccess, onError) {
  const filter = {};
  let endpoint = API.post(`rides/filter`, filter);
  endpoint.then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}


export function doGetRidesByDay(fromDay, toDay, onSuccess, onError) {
  API.get(`rides/offset/${fromDay}/${toDay}`)
  .then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}
