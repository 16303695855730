const hostname = window.location.hostname;
const protocol = window.location.protocol;

const develop = {
  ENV: "develop",
  IS_DEMO: false,
  HOSTNAME: hostname,
  API_URL: `${protocol}//${hostname}:3001/`
};

const demo = {
  ENV: "demo",
  IS_DEMO: true,
  HOSTNAME: hostname,
  API_URL: `${protocol}//${hostname}:3001/`
};

const production = {
  ENV: "production",
  IS_DEMO: false,
  HOSTNAME: hostname,
  API_URL: `${protocol}//api.${hostname}/`
};

const productiondemo = {
  ENV: "productiondemo",
  IS_DEMO: true,
  HOSTNAME: hostname,
  API_URL: `${protocol}//api.${hostname}/`
};


let config = develop;
if(process.env.REACT_APP_STAGE === 'production') {
  config = production
} else if(process.env.REACT_APP_STAGE === 'demo') {
  config = demo
} else if(process.env.REACT_APP_STAGE === 'productiondemo') {
  config = productiondemo
}

export default {
  // Add common config values here
  //MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
