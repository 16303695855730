import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import ColorPicker from 'material-ui-color-picker'

import './AddEditCar.css';

class AddEditCar extends React.Component {
  constructor(){
    super();
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.changeName = this.changeName.bind(this);
    this.changeLicensePlate = this.changeLicensePlate.bind(this);

    // if edit, we can initialise the state
    // and assign the id.

    this.id = -1;
    this.initialState = {
      name: "",
      license_plate: "",
    };

    this.state = this.initialState;
  }

  // Check if we selected a car or not.
  componentDidUpdate(prevProps) {
    const { currentCar } = this.props;
    if(prevProps.currentCar !== currentCar) {
      if(currentCar) {
        this.id = currentCar.id
        this.setState({
          ...currentCar,
          error: false
        })
      } else {
        this.id = -1;
        this.setState(this.initialState);
      }
    }
  }

  handleClose() {
    this.props.onClose();
  }

  validateForm() {
    const { name, license_plate } = this.state;
    if(!this.checkIfValid(name)) {
      return false;
    }
    if(!this.checkIfValid(license_plate)) {
      return false;
    }
    return true;
  }

  handleSubmit(event) {
    event.preventDefault();
    if(this.validateForm()){
      // Send data..
      this.props.saveCar(this.id, { ...this.state })
      this.handleClose();
      this.setState({error: false});
    }
    else {
      this.setState({error: true});
    }
  }

  checkIfValid(value) {
    return value && value.length > 0;
  }

  changeName(e) {
    this.setState({ name: e.target.value });
  }

  changeLicensePlate (e){
    this.setState({ license_plate: e.target.value });
  }
  render() {

      const { error } = this.state;

      return <div>
        <Dialog open={this.props.open} onClose={this.handleClose} maxWidth="sm" aria-labelledby="form-dialog-title">

          <DialogTitle id="form-dialog-title">{ this.id !== -1 ? 'Edit' : 'Add a new' } car</DialogTitle>
          <form onSubmit={this.handleSubmit}>
            <DialogContent>
                { error && <DialogContentText className="error">Please fill-in all fields correctly.</DialogContentText> }
                <Grid container={true} spacing={0}>
                  <Grid item xs={12}>

                    <TextField
                      margin="dense"
                      id="name"
                      label="Car Name"
                      type="text"
                      fullWidth
                      value={ this.state.name }
                      onChange={ this.changeName }/>

                    <TextField
                      margin="dense"
                      id="license_plate"
                      label="License Plate"
                      type="text"
                      fullWidth
                      value={ this.state.license_plate }
                      onChange={ this.changeLicensePlate }/>

                  </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.handleClose()} color="primary">
                Cancel
              </Button>
              <Button color="primary" type="submit">
                { this.id !== -1 ? "Edit" : "Add" } Car
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>;
  }
}

export default AddEditCar;
