import React from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import AddEditCar from '../components/AddEditCar/AddEditCar';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit'
import DomainDisabled from '@material-ui/icons/DomainDisabled'
import ConfirmDialog from '../components/ConfirmDialog/ConfirmDialog';
import Tooltip from '@material-ui/core/Tooltip';
import "./Cars.css";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { deleteCar, addEditCar, getCars } from '../actions'

class Cars extends React.Component {

  constructor() {
    super();
    this.state = {
      open: false,
      openDelete: false,
      currentCar: null
    };
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.handleClickAddOpen = this.handleClickAddOpen.bind(this);
    this.handleClickEditOpen = this.handleClickEditOpen.bind(this);
    this.handleDeleteOpen = this.handleDeleteOpen.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    this.props.dispatchGetCars();
  }

  save(id, data) {
    this.props.dispatchAddEditCar(id, data);
  }

  delete() {
    const { id } = this.state.currentCar;
    this.props.dispatchDeleteCar(id);
  }

  handleClickAddOpen() {
    this.setState({
      open: true,
      currentCar: null
    });
  }

  handleClickEditOpen(user) {
    this.setState({
      open: true,
      currentCar: user
    });
  }

  handleClose() {
    this.setState({
      open: false
    });
  }

  handleDeleteOpen(user) {
    this.setState({
      openDelete: true,
      currentCar: user
    });
  }

  handleDeleteClose() {
    this.setState({
      openDelete: false,
    });
  }

  render(){
    const { listOfCars } = this.props;

    return <div>
      <Grid container spacing={0}>
        <Paper className="fullWidth">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Actions</TableCell>
                <TableCell>Id</TableCell>
                <TableCell align="right">Car</TableCell>
                <TableCell align="right">License Plate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listOfCars && listOfCars.map(row => (
                <TableRow key={row.id}>
                  <TableCell padding="checkbox" style={{ width: "150px" }}>

                      <Tooltip title="Edit Car" placement="top">
                        <EditIcon onClick={() => this.handleClickEditOpen(row)} className="icon"/>
                      </Tooltip>
                      <Tooltip title="Delete Car" placement="top">
                        <DeleteIcon onClick={() => this.handleDeleteOpen(row)} className="icon"/>
                      </Tooltip>
                      <Tooltip title="Disable Car" placement="top">
                        <DomainDisabled className="inactive"/>
                      </Tooltip>

                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell align="right">{row.name}</TableCell>
                  <TableCell align="right">{row.license_plate}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Tooltip title="Add a new car" placement="top">
        <Fab color="primary" aria-label="Add" className="add" onClick={this.handleClickAddOpen}>
          <AddIcon/>
        </Fab>
      </Tooltip>
      <AddEditCar
        saveCar={this.save}
        currentCar={this.state.currentCar}
        open={this.state.open}
        onClose={this.handleClose} />

      { this.state.currentCar &&
        <ConfirmDialog
          onSubmit={this.delete}
          title="Delete Car"
          message={ "You're about to delete '" + this.state.currentCar.name + "'. Are you sure?" }
          open={this.state.openDelete}
          handleClose={this.handleDeleteClose} /> }
    </div>
  }
}

const mapStateToProps = (state, ownProps) => ({
  listOfCars: state.cars.list
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchAddEditCar: (id, data) => dispatch(addEditCar(id, data)),
  dispatchDeleteCar: (id) => dispatch(deleteCar(id)),
  dispatchGetCars: () => dispatch(getCars())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cars));
