import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware, ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers'
import thunk from 'redux-thunk';
import App from './pages/App';
import Login from './pages/Login';
import Home from './pages/Home';
import Users from './pages/Users';
import Cars from './pages/Cars';
import Reports from './pages/Reports';
import Schedule from './pages/Schedule';
import RequireAuth from './containers/RequireAuth';
import RequireGuest from './containers/RequireGuest';
import './index.css';
import * as serviceWorker from './serviceWorker';
export const history = createBrowserHistory();

// We get the token from the store to initialise the store.
// So we know if the user is still signed in.
function getAuthState() {
  try {
    const token = localStorage.getItem('token') || undefined;
    const expire = localStorage.getItem('expire') || undefined;
    const username = localStorage.getItem('username') || undefined;
    const role = localStorage.getItem('role') || undefined;
    const difference = new Date(expire)-new Date();
    const state = {
      auth: {
        token,
        expire,
        username,
        role,
        loggedIn: difference >= 0
      }
    };
    return state;
  } catch (err) { return undefined; }
}

const store = createStore(
  rootReducer(history),
  { ...getAuthState() },
  composeWithDevTools(
    applyMiddleware(
      thunk,
      routerMiddleware(history)
    )
  )
);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/login" component={RequireGuest(Login)} />
        <App>
          <Route exact path="/" component={RequireAuth(Home)} />
          <Route path="/users" component={RequireAuth(Users)} />
          <Route path="/cars" component={RequireAuth(Cars)} />
          <Route path="/schedule" component={RequireAuth(Schedule)} />
          <Route path="/reports" component={RequireAuth(Reports)} />
        </App>
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
